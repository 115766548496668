import SampleSize from "./SampleSize";
import CheckboxList from "../../../lib/checkboxList/CheckboxList";
import StepButtons from "./StepButtons";
import IStep from "./interfaces/IStep";
import {useAudience} from "../../context/AudienceContext";

export default function InterestsStep({audienceName, onBack, onNext }: IStep){
    const {areasOfInterest, updateAreasOfInterest, canAddMoreAreasOfInterest} = useAudience();

    return(
        <div>
            <div className="margin__bottom--s">
                <h2 className="margin__bottom--s">Editing audience: {audienceName}</h2>
                {/* <SampleSize /> */}
                <p className="margin__bottom--s">Choose up to three areas of interest for your audience.</p>
                <div className="create-audiences__scroll-area">
                    <div className="box">
                        <CheckboxList items={areasOfInterest} onChange={updateAreasOfInterest} reachedMaximum={canAddMoreAreasOfInterest} />
                    </div>
                </div>
            </div>
            <StepButtons onBack={onBack} onNext={onNext} nextLabel="Save" skipShown skipLabel="Skip & save"/>
        </div>
    );
}