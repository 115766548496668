import React from 'react';

export interface IInputRadio {
	labelClassName?: string,
	className?: string,
	id?: string,
	name?: string,
	label?: string,
	checked?: boolean,
	value?: string,
	onChangeCheckBox: Function,
	disabled?: boolean,
	dotColor?: string
}

export default function InputRadio({ labelClassName, className, id, name, label, checked, value, onChangeCheckBox, disabled, dotColor, ...other }: IInputRadio) {

	function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
		if (onChangeCheckBox) {
			onChangeCheckBox(e)
		}
	}

	return <div className={`input-radio__wrapper ${className ? className : ''}`}>
		<input className={`input-radio__input`} checked={checked} type="radio" name={name} value={value} key={value} id={id} onChange={handleChange} disabled={disabled} {...other} />
		<span className={`input-radio-custom`} />
		{dotColor &&
			<div className={`input-radio__dot ${dotColor}`}></div>
		}
		<label className={`input-radio__label ${labelClassName}`} htmlFor={id}>
			{label}
		</label>
	</div>
}