import {ICheckboxListItem} from "../interfaces/ICheckboxListItem";

export function mapUrban(mappings: any) {
    return mappings.map((m: any) => {
        return {
            label: m.text,
            value: m.value,
            checked: true
        } as ICheckboxListItem
    });
}

export function mapEnvironmentFromSelected(selectedAudience: any, environments: ICheckboxListItem[]) {
    var tempEnvironments = [...environments];
    var statuses = selectedAudience.questionnaire.demographic.urban;

    tempEnvironments.forEach((g) => g.checked = false);

    for(var i = 0; i < statuses.length; i++) {
        var environment = tempEnvironments.find((m) => m.value === statuses[i]);
        if(environment !== undefined) {
            environment.checked = true;
        }
    }
    return tempEnvironments;
}