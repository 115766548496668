import {ICheckboxListItem} from "../interfaces/ICheckboxListItem";

export function mapMaritalStatuses(mappings: any) {
    return mappings.map((m: any) => {
        return {
            label: m.text,
            value: m.value,
            checked: true
        } as ICheckboxListItem
    });
}

export function mapMaritalStatusesFromSelected(selectedAudience: any, maritalStatuses: ICheckboxListItem[]) {
    var tempMaritalStatuses = [...maritalStatuses];
    var statuses = selectedAudience.questionnaire.demographic.marital;

    tempMaritalStatuses.forEach((g) => g.checked = false);

    for(var i = 0; i < statuses.length; i++) {
        var maritalStatus = tempMaritalStatuses.find((m) => m.value === statuses[i]);
        if(maritalStatus !== undefined) {
            maritalStatus.checked = true;
        }
    }
    return tempMaritalStatuses;
}