import {IEducation} from "../interfaces/IEducation";
import IRange from "../interfaces/IRange";

export function mapEducations(mappings: any) {
    var educations = mappings.map((m: any) => {
        return {
            text: m.text,
            value: m.value
        } as IEducation
    });
    const range = {minimum: 0, maximum: educations.length - 1} as IRange;

    return {educations: educations, range: range};
}

export function mapEducationFromSelected(selectedAudience: any, educations: IEducation[]) {
    var minIndex = educations.findIndex((e) => e.value === selectedAudience.questionnaire.demographic.education[0]);
    var maxIndex = educations.findIndex((e) => e.value === selectedAudience.questionnaire.demographic.education[1]);
    var educationRange = {minimum: minIndex, maximum: maxIndex} as IRange;
    return educationRange;
}