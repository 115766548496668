import React from 'react';
import {useLocation, useNavigate} from "react-router-dom";
import {administration, analytics, audiences, guides, root} from '../../../modules/Navigation';

export interface ISideMenuItem {
    text: string,
    activeIcon: any,
    inactiveIcon: any,
    navigateTo: any,
    disabled?: boolean,
    className?: string
}

export default function SideMenuItem({text, inactiveIcon, activeIcon, navigateTo, className, disabled = false}: ISideMenuItem) {
    const location = useLocation();

    function checkNavigationPathAgainstParent(path: string): boolean {
        switch (navigateTo) {
            case root.to:
                return path === "/";
            case audiences.to:
                return path.includes('audience');
            case analytics.to:
                return false;
            case guides.to:
                return false;
            case administration.to:
                return false;
            default:
                return false
        }
    }

    const navigate = useNavigate();

    const handleNavigation = () => {
        if(disabled) return;
        navigate(navigateTo);
    };

    return <>
        <div className={`side-menu__item ${disabled ? "side-menu__item-disabled" : ""} ${checkNavigationPathAgainstParent(location.pathname) ? "active" : ""} ${className ? className : ''} `} onClick={handleNavigation}>
            <div className={`${checkNavigationPathAgainstParent(location.pathname) ? "side-menu__item__icon--shadow" : ""}`}>
                {
                    checkNavigationPathAgainstParent(location.pathname) && disabled !== true ?
                        <img className={`side-menu__item__icon`} src={activeIcon}/> :
                        <img className={`side-menu__item__icon`} src={inactiveIcon}/>
                }
            </div>

            <h3 className={"side-menu__item__text"}>
                {text}
            </h3>

        </div>
    </>
}