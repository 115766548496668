import React, {useEffect} from 'react';

export interface IInputCheckbox {
	labelClassName?: string,
	className?: string,
	id?: string,
	name?: string,
	label?: string,
	checked?: boolean,
	value: string,
	onChangeCheckBox: Function,
	disabled?: boolean,
	isHeading?: boolean,
}

export default function InputCheckbox({ labelClassName, className, id, name, label, checked, value, onChangeCheckBox, disabled = false, ...other }: IInputCheckbox) {

	function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
		if (onChangeCheckBox) {
			onChangeCheckBox(e)
		}
	}

	useEffect(() => {


	}, [value])

	return <div className={`input-checkbox__wrapper ${className ? className : ''}`}>
		<label className={`${labelClassName ? labelClassName : "input-checkbox__label"}`} htmlFor={id}>
			{label}
		</label>
		<input className={`input-checkbox__input`} checked={checked} type="checkbox" name={name} value={value} key={value} id={id} onChange={handleChange} disabled={disabled} {...other} />
		<span className={`input-checkbox-custom`} />
	</div>
}
