import { useEffect } from "react";

export default function Terms() {
    // useEffect(() => {
    //     const termly = document.createElement("div");
    //     termly.name = "termly-embed";
    //     termly["data-id"] = "e25744d8-89b9-4df6-924e-376f96bbeb96";
    
    //     const termly2 = document.getElementById('termly');
    //     termly2.appendChild(termly);

    //     const body = document.body;
    //     const script = document.createElement("script");
    //     script.src = "https://app.termly.io/embed-policy.min.js";
    //     script.async = true;
    //     body.appendChild(script);
    // }, []);    

    // return <div id="termly"></div>;

    return <iframe src="./terms-and-conditions.html" style={{ width: "100%", height: "100%" }} />
}
