import SampleSize from "./SampleSize";
import CheckboxList from "../../../lib/checkboxList/CheckboxList";
import StepButtons from "./StepButtons";
import IStep from "./interfaces/IStep";
import { useAudience } from "../../context/AudienceContext";
import InputText from "../../../lib/inputs/InputText";


export default function InterestsStep({ audienceName, onBack, onNext }: IStep) {
  const { customPrompt, setCustomPrompt } = useAudience();

  console.log(customPrompt)

  return (
    <div>
      <div className="margin__bottom--s">
        <h2 className="margin__bottom--s">Editing audience: {audienceName}</h2>
        {/* <SampleSize /> */}
        <p className="margin__bottom--s">Engage with your audience by adding custom style instructions for Fubilized copy.</p>
        <div className="box">
        <p className="margin__bottom--s">Custom instructions</p>
          <InputText value={customPrompt}
            placeholder="e.g. be informal, avoid Americanisms..."
            onChangeText={(e: React.ChangeEvent<HTMLInputElement>) => setCustomPrompt(e.target.value)} />
        </div>
      </div>
      <StepButtons onBack={onBack} onNext={onNext} skipShown />
    </div>
  );
}