import {useAudience} from "../../context/AudienceContext";
import InputCheckbox from "../../../lib/inputs/InputCheckbox";
import StepButtons from "./StepButtons";
import SampleSize from "./SampleSize";
import IStepAreas from "./interfaces/IStepAreas";
import {IContinent} from "../interfaces/IContinent";
import {ICountry} from "../interfaces/ICountry";
import {useState} from "react";

export default function SelectCountryStep({onBack, onNext}: IStepAreas) {
    const {newAudienceName, continents, countrySelected } = useAudience();

    return (
        <div>
            <div className="margin__bottom--s">
                <h2 className="margin__bottom--s">Editing audience: {newAudienceName}</h2>
                {/* <SampleSize /> */}
                <p className="margin__bottom--s">
                    Which countries does this audience live in?
                </p>
                <div className="create-audiences__scroll-area">
                    {continents && continents.filter((continent: any) => continent.checked === true).map((continent: IContinent, index: number) => {
                        return <ContinentCountries continent={continent} index={index}/>
                    })
                    }
                </div>
            </div>
            <StepButtons onBack={onBack} onNext={onNext} nextDisabled={!countrySelected} skipShown />
        </div>
    );
}

interface IContinentCountries {
    continent: IContinent;
    index: number;
}

function ContinentCountries({continent, index}: IContinentCountries) {
    const {onCountrySelected, toggleSelectAllCountriesInContinent } = useAudience();
    const [selectAll, setSelectAll] = useState<boolean>(continent.countries.every((country: ICountry) => country.checked));

    function onSelect(country: ICountry, e : React.ChangeEvent<HTMLInputElement>) {
        var checked = e.target.checked;
        if(selectAll && !checked) {
            setSelectAll(false);
        }
        else if(!selectAll && checked) {
            var allChecked = continent.countries.filter(c => c.name !== country.name).every((country: ICountry) => country.checked);
            if(allChecked) {
                setSelectAll(true);
            }
        }
        onCountrySelected(country);
    }

    return (
        <div className="box margin__bottom--xs"
             key={`${index}-continent`}>
            <InputCheckbox
                className="left-align"
                value={""}
                onChangeCheckBox={() => {
                    var selected = !selectAll;
                    setSelectAll(selected);
                    toggleSelectAllCountriesInContinent(continent, selected);
                }}
                label={continent.name}
                labelClassName="h3 margin__bottom--s text-transform--capitalize"
                checked={selectAll}/>
            <div className="padding__top--xs padding__left-right--xs create-audiences__checkbox-grid">
                {continent.countries && continent.countries.map((country: ICountry, index: number) => {
                    return (
                        <InputCheckbox
                            key={`${index}-country`}
                            className="left-align"
                            label={country.name}
                            value={""}
                            checked={country.checked}
                            onChangeCheckBox={(e : React.ChangeEvent<HTMLInputElement>) => onSelect(country, e)}/>
                    );
                })}
            </div>
        </div>
    );
}
