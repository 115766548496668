import InputCheckbox from "../../../../lib/inputs/InputCheckbox";
import {useAudience} from "../../../context/AudienceContext";
import {ICheckboxListItem} from "../../interfaces/ICheckboxListItem";

export default function HomeOwnershipSelector() {
    const {homeOwnerships, updateHomeOwnerships} = useAudience();

    return <div className="margin__bottom--m">
        <p className="margin__bottom--s">Home ownership</p>
        <div className="create-audiences__checkbox-grid">
            {
                homeOwnerships && homeOwnerships.map((ownership: ICheckboxListItem, index: number) => {
                    return <InputCheckbox
                        key={`home-ownership-${index}`}
                        className="left-align"
                        label={ownership.label}
                        checked={ownership.checked}
                        value={""}
                        onChangeCheckBox={() => updateHomeOwnerships(ownership)}
                    />
                })
            }
        </div>
    </div>
}