import MultiRangeSlider, {ChangeResult} from "multi-range-slider-react";
import {useAudience} from "../../../context/AudienceContext";

export default function EducationRangeSelector() {
    const {educationRange, updateEducationRange, educations} = useAudience();

    return <div>
        <p className="margin__bottom--s">Education</p>
        <MultiRangeSlider
            labels={educations.map(e => e.text)}
            min={0}
            max={educations.length - 1}
            step={1}
            minValue={educationRange.minimum}
            maxValue={educationRange.maximum}
            onInput={(e: ChangeResult) => updateEducationRange(e.minValue, e.maxValue)}
        />
    </div>
}