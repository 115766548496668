import {useAudience} from "../../context/AudienceContext";
import SampleSize from "./SampleSize";
import InputCheckbox from "../../../lib/inputs/InputCheckbox";
import StepButtons from "./StepButtons";
import IStepAreas from "./interfaces/IStepAreas";
import React, {useState} from "react";
import {IContinent} from "../interfaces/IContinent";
import {ICountry} from "../interfaces/ICountry";
import {IRegion} from "../interfaces/IRegion";

export default function SelectRegionStep({onBack, onNext}: IStepAreas) {
    const { newAudienceName, continents, regionSelected } = useAudience();

    return (
        <div>
            <div className="margin__bottom--s">
                <h2 className="margin__bottom--s">Editing audience: {newAudienceName}</h2>
                {/* <SampleSize/> */}
                <p className="margin__bottom--s">Choose specific regions for each country you selected.</p>
                <div className="create-audiences__scroll-area">
                    {
                        continents && continents.filter((continent: IContinent) => continent.checked).map((continent: any, index: number) => {
                            return (
                                <React.Fragment
                                    key={`${index}-continent`}>
                                    {
                                        continent.countries && continent.countries.filter((country: ICountry) => country.checked).map((country: ICountry, index: number) => {
                                            return <CountryRegions country={country} index={index}/>
                                        })
                                    }
                                </React.Fragment>
                            );
                        })
                    }
                </div>
            </div>
            <StepButtons onBack={onBack} onNext={onNext} nextDisabled={!regionSelected} skipShown/>
        </div>
    );
}

interface ICountryRegions {
    country: ICountry;
    index: number;
}

function CountryRegions({country, index}: ICountryRegions) {
    const { onRegionSelected, toggleSelectAllRegionsInCountry } = useAudience();
    const [selectAll, setSelectAll] = useState<boolean>(country.regions.every((region: IRegion) => region.checked));

    function onSelect(region: IRegion, e : React.ChangeEvent<HTMLInputElement>) {
        var checked = e.target.checked;
        if(selectAll && !checked) {
            setSelectAll(false);
        }
        else if(!selectAll && checked) {
            var allChecked = country.regions.filter(r => r.value !== region.value).every((region: IRegion) => region.checked);
            if(allChecked) {
                setSelectAll(true);
            }
        }
        onRegionSelected(region);
    }

    return (
        <div className="box margin__bottom--xs"
             key={`${index}-country`}>
            <InputCheckbox
                className="left-align"
                value={""}
                onChangeCheckBox={() => {
                    var selected = !selectAll;
                    setSelectAll(selected);
                    toggleSelectAllRegionsInCountry(country, selected);
                }}
                label={country.name}
                labelClassName="h3 margin__bottom--s text-transform--capitalize"
                checked={selectAll}/>
            <div
                className="padding__top--xs padding__left-right--xs create-audiences__checkbox-grid">
                {
                    country.regions && country.regions.map((region: IRegion, index: number) => {
                        return (
                            <InputCheckbox
                                key={`${index}-region`}
                                className="left-align"
                                label={region.text}
                                value={""}
                                checked={region.checked}
                                onChangeCheckBox={(e: React.ChangeEvent<HTMLInputElement>) => onSelect(region, e)}/>
                        );
                    })
                }
            </div>
        </div>
    );
}