export default function Button({className, type, destructive = false, outline = false, size, text, disabled, children, externalStyles = {}, ...other}) {

function getTypeClass(){
	switch(type){
		case 'primary':
			return `button--primary${destructive === true ? '--destructive':''}${outline === true && destructive !== true ? '--outline':''}`;
		case 'secondary':
			return `button--secondary${destructive === true ? '--destructive':''}${outline === true && destructive !== true ? '--outline':''}`;
		case 'tertiary':
			return `button--tertiary${destructive === true ? '--destructive':''}${outline === true && destructive !== true ? '--outline':''}`;
		case `text-only`:
			return `button--text-only${destructive === true ? '--destructive':''}`;
		default:
			return 'button--primary';
	}
}

function getSizeClass(){
	switch(size){
		case 'small':
			return 'button--small button-text--small';
		case 'large':
			return 'button--large  button-text';
		default:
			return 'button-text';
	}
}

function validateProps(){
	if(type && type!=='primary' && type!=='secondary'  && type!=='text-only'){
		console.warn('Button component: type prop must be primary, secondary or text-only');
	}
	if(size && size!=='small' && size!=='large'){
		console.warn('Button component: size prop must be small or large');
	}
}

validateProps()
	return <button className={`button ${getTypeClass()} ${getSizeClass()} ${className ? className : ''}`} disabled={disabled} style={{...externalStyles}} {...other}>
		{children}<span>{text}</span>
	</button>
}
