import InputText from "../../../lib/inputs/InputText";
import Button from "../../../lib/buttons/Button";
import { useAudience } from "../../context/AudienceContext";

interface IStepOne {
  onNext: Function;
}

export default function SelectAudienceNameStep({ onNext }: IStepOne) {
  const { newAudienceName, setNewAudienceName, audiences } = useAudience();

  return (
    <div>
      <div className="box margin__bottom--s">
        <h1 className="margin__bottom--s">Create new audience</h1>
        <p className="margin__bottom--xxs">Audience name</p>
        <InputText value={newAudienceName}
          className="margin__bottom--s"
          onChangeText={(e: React.ChangeEvent<HTMLInputElement>) => setNewAudienceName(e.target.value)} />
      </div>
      <div className="display--flex step-one__button-wrapper">
        <Button
          text={"Use a template"}
          className={"step-one__button"}
          type={"secondary"}
          size={"large"}
          outline={true}
          disabled={newAudienceName === null || newAudienceName === "" || audiences.length === 0}
          onClick={() => onNext(true)}
          children={null}
        />
        <p>or</p>
        <Button
          text={"Create from scratch"}
          className={"step-one__button"}
          type={"secondary"}
          size={"large"}
          outline={true}
          disabled={newAudienceName === null || newAudienceName === ""}
          onClick={() => onNext()}
          children={null}
        />
      </div>
    </div>
  );
}