import React, {useEffect, useState} from 'react';
import AudienceIcon from "../../../assets/icons/icon-audience.svg";
import {useAudience} from "../context/AudienceContext";
import Button from "../../lib/buttons/Button";
import * as Navigation from "../../../modules/Navigation";
import {useNavigate} from "react-router-dom";

export interface IAudiencesListItem {
    audience: any
}

export default function AudiencesListItem(audience : IAudiencesListItem) {
    const {getMaritalStatusCommaSeperatedList, getGenderCommaSeperatedList} = useAudience();
    const navigate = useNavigate();
    const [genders, setGenders] = useState('');
    const [maritalStatuses, setMaritalStatuses] = useState('');

    useEffect(() => {
        setGenders(getGenderCommaSeperatedList(audience.audience.questionnaire.demographic.sex))
        setMaritalStatuses(getMaritalStatusCommaSeperatedList(audience.audience.questionnaire.demographic.marital))
    }, [audience]);

    return (
        <div className="audiences-item__wrapper">


            <div className="audiences-item__audience">
                <img className="audiences-item__audience--icon" src={AudienceIcon} alt="Audience"/>
                <div className="audiences-item__audience--title-wrapper">
                    <p className="audiences-item__audience--title small">{audience.audience.name}</p>
                </div>
            </div>

            <div className="audiences-item__audience--fields">
                <div className="audiences-item__audience--field">
                    <p className="audiences-item__audience--field__title small--2">Gender(s)</p>
                    <div className="audiences-item__audience__value-wrapper">
                        {
                            genders !== '' &&
                            <p className="audiences-item__audience-field-title p">{genders}</p>
                        }
                        {
                            genders === '' &&
                            <p className="audiences-item__audience-field-title p">N/A</p>
                        }
                    </div>
                </div>
                <div className="audiences-item__audience--field">
                    <p className="audiences-item__audience--field__title small--2">Age Range</p>
                    {audience?.audience?.questionnaire?.demographic?.age &&
                        <p className="audiences-item__audience-field-title p">{audience?.audience?.questionnaire?.demographic?.age[0]} - {audience?.audience?.questionnaire?.demographic?.age[1]}</p>
                    }
                    {!audience?.audience?.questionnaire?.demographic?.age &&
                        <p className="audiences-item__audience-field-title p">N/A</p>
                    }
                </div>
                <div className="audiences-item__audience--field">
                    <p className="audiences-item__audience--field__title small--2">Marital Status(es)</p>
                    <div className="audiences-item__audience__value-wrapper">
                        {
                            maritalStatuses !== '' &&
                            <p className="audiences-item__audience-field-title p">{maritalStatuses}</p>
                        }
                        {
                            maritalStatuses === '' &&
                            <p className="audiences-item__audience-field-title p">N/A</p>
                        }
                    </div>
                </div>
            </div>
            <Button text="View"
                    outline={true}
                    size="small"
                    type="secondary"
                    onClick={() => navigate(`${Navigation.audience.to}/${audience.audience.id}`)}
                    className={"audiences-item__view"}
                    destructive={false}
                    disabled={false}
                    children={null}/>
        </div>
    )
}