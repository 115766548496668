import {useState} from "react";
import {useAudience} from "../context/AudienceContext";
import SelectAudienceNameStep from "./steps/SelectAudienceNameStep";
import CustomInstructionsStep from "./steps/CustomInstructionsStep";
import SelectContinentStep from "./steps/SelectContinentStep";
import SelectCountryStep from "./steps/SelectCountryStep";
import SelectRegionStep from "./steps/SelectRegionStep";
import DemographicStep from "./steps/DemographicStep";
import InterestsStep from "./steps/InterestsStep";
import AttitudesStep from "./steps/AttitudesStep";
import StepSelectTemplate from "./steps/StepSelectTemplate";

interface IAudienceSteps {
    onComplete: Function;
}

export default function AudienceSteps({onComplete}: IAudienceSteps) {
    const {newAudienceName, onCreateAudience, setFromTemplate, fromTemplate} = useAudience();

    const [stepIndex, setStepIndex] = useState<number>(1);

    function nextStep(fromTemplate: boolean = false) {
        if(stepIndex === 1 && !fromTemplate) {
            setStepIndex(stepIndex + 2);
            return;
        }
        if(stepIndex === 1 && fromTemplate) {
            setFromTemplate(true);
        }
        setStepIndex(stepIndex + 1);
    }
    function previousStep() {
        if(stepIndex === 3 && !fromTemplate) {
            setStepIndex(stepIndex - 2);
            return;
        }
        setStepIndex(stepIndex - 1);
    }

    async function onCreation() {
        await onCreateAudience();
        onComplete();
    }

    return (
        <div>
            {stepIndex === 1 && (
                <SelectAudienceNameStep
                    onNext={nextStep}
                />
            )}
            {stepIndex === 2 && (
                <StepSelectTemplate
                    onNext={nextStep}
                    onBack={previousStep}
                />
            )}
            {stepIndex === 3 && (
                <CustomInstructionsStep
                    audienceName={newAudienceName}
                    onNext={nextStep}
                    onBack={previousStep}
                />
            )}
            {stepIndex === 4 && (
                <SelectContinentStep
                    onBack={previousStep}
                    onNext={nextStep}
                />
            )}
            {stepIndex === 5 && (
                <SelectCountryStep
                    onBack={previousStep}
                    onNext={nextStep}
                />
            )}
            {stepIndex === 6 && (
                <SelectRegionStep
                    onBack={previousStep}
                    onNext={nextStep}
                />
            )}
            {stepIndex === 7 && (
                <DemographicStep
                    audienceName={newAudienceName}
                    onBack={previousStep}
                    onNext={nextStep}
                />
            )}
            {stepIndex === 8 && (
                <AttitudesStep
                    audienceName={newAudienceName}
                    onBack={previousStep}
                    onNext={nextStep}
                />
            )}
            {stepIndex === 9 && (
                <InterestsStep
                    audienceName={newAudienceName}
                    onBack={previousStep}
                    onNext={onCreation}
                />
            )}
        </div>
    );
}












