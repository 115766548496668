import StepButtons from "./StepButtons";
import IStep from "./interfaces/IStep";
import EnvironmentSelector from "./selectors/EnvironmentSelector";
import HomeOwnershipSelector from "./selectors/HomeOwnershipSelector";
import ProfessionSelector from "./selectors/ProfessionSelector";
import MaritalStatusSelector from "./selectors/MaritalStatusSelector";
import EducationRangeSelector from "./selectors/EducationRangeSelector";
import AgeRangeSelector from "./selectors/AgeRangeSelector";
import GenderSelector from "./selectors/GenderSelector";

export default function DemographicStep({audienceName, onBack, onNext }: IStep) {
    return (
        <div>
            <div className="margin__bottom--s">
                <h2 className="margin__bottom--s">Editing audience: {audienceName}</h2>
                <p className="margin__bottom--s">
                    What demographic does this audience describe?
                </p>

                <div className="create-audiences__scroll-area">
                    <GenderSelector />
                    <div className="box margin__bottom--xs">
                        <AgeRangeSelector />
                        <EducationRangeSelector />
                    </div>
                    <div className="box">
                        <ProfessionSelector />
                        <MaritalStatusSelector />
                        <HomeOwnershipSelector />
                        <EnvironmentSelector />
                    </div>
                </div>
            </div>
            <StepButtons onBack={onBack} onNext={onNext} skipShown/>
        </div>
    );
}