import React, {useEffect, useState} from "react";
import SideMenu from "./SideMenu";
import Header from "./Header";

export interface ILayout {
    pageTitle?: string;
    showBackButton?: boolean;
    children?: any,
    showBreadcrumb: boolean
    dynamicViewSize?: boolean
}

export default function Layout({pageTitle, showBackButton, children, showBreadcrumb, dynamicViewSize}: ILayout) {
    const [showMobileMenu, setShowMobileMenu] = useState(false);
    const [hoveringMenu, setHoveringMenu] = useState(false);

    useEffect(() => {
        if (showMobileMenu === true) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "";
        }
    }, [showMobileMenu])

    return <>
        <div className="grid-container full layout">
            <div className="grid-x">
                <div className="cell shrink">
                    <SideMenu showMobileMenu={showMobileMenu} setShowMobileMenu={setShowMobileMenu} setHoveringMenu={setHoveringMenu} hoveringMenu={hoveringMenu}/>
                </div>

                <div className={`cell auto layout__content-wrapper ${hoveringMenu ? "hoveringMenu" : ""}`}>
                    <Header title={pageTitle} setShowMobileMenu={setShowMobileMenu} showBackButton={showBackButton} showBreadcrumb={showBreadcrumb} contextNoPadding={dynamicViewSize}/>
                    <div className="grid-x">
                        <main className={`cell small-12 ${dynamicViewSize ? "" : "layout__content"}`}>
                            {children}
                        </main>
                    </div>
                </div>
            </div>
        </div>
    </>
};