import { useState } from 'react';import {
  useFloating,
  useHover,
  useInteractions,
  autoUpdate,
  offset,
  flip,
  shift,
} from '@floating-ui/react';
import IconAudienceActive from "../../../assets/icons/icon-audience-active.svg";
import IconAudience from "../../../assets/icons/icon-audience.svg";

interface IAudienceCard {
    elementKey: number,
    selected: boolean,
    audience: any,
    onSelect: Function,
    preventSelectedHighlighting?: boolean
}

export default function AudienceCard({elementKey, selected, audience, onSelect, preventSelectedHighlighting = false}: IAudienceCard) {
    const hasPopover = audience?.description?.headline || audience?.description?.description;
      const [isOpen, setIsOpen] = useState(false);
      const {refs, floatingStyles, context} = useFloating({
        open: isOpen,
        onOpenChange: setIsOpen,
        middleware: [offset(10), flip(), shift()],
        whileElementsMounted: autoUpdate,
      });
  
      const hover = useHover(context, {move: false});
      const {getReferenceProps, getFloatingProps} = useInteractions([ hover ]);


    return <>
      <button ref={refs && refs.setReference} {...(getReferenceProps && getReferenceProps())} key={`audience-card-${elementKey}`} className={`button fubilizer__audience ${selected && !preventSelectedHighlighting ? "active" : selected && preventSelectedHighlighting ? "active--minimal" : ""}`} onClick={() => onSelect(audience)}>
          <img className="fubilizer__audience--icon"  src={ selected && !preventSelectedHighlighting ? IconAudienceActive : IconAudience}  alt="Audience icon"/>
          <div className="fubilizer__audience--name-wrapper">
              <p className="fubilizer__audience--name small">{audience.name}</p>
          </div>
      </button>

      {hasPopover && isOpen && (
        <div className="box"
          ref={refs && refs.setFloating}
          style={{...floatingStyles, boxShadow:"0 8px 16px rgba(0,0,0,.25)"}}
          {...(getFloatingProps && getFloatingProps())}>
            <p className="p p--bold">{audience.description?.headline}</p>
            <p className="">{audience.description?.description}</p>
        </div>
      )}
    </>;
}