import Layout from "../layout/Layout";
import AudienceDetail from "../audiences/AudienceDetail";
import React from "react";
import {RouteComponentProps} from "@reach/router";
import { useParams } from 'react-router-dom'

interface IAudiencePageProps  extends RouteComponentProps {
    id?: number;
}

export default function AudiencePage(props: IAudiencePageProps) {
    const  {id} = useParams();

    return (
        <Layout pageTitle="" showBreadcrumb={false} showBackButton={true} dynamicViewSize={true}>
            {id &&
                <AudienceDetail id={id}/>
            }
        </Layout>
    )
}