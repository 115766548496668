import Layout from "../layout/Layout";
import Fubilizer from "../fubilizer/Fubilizer";


export default function HomePage() {

    return (
        <Layout showBreadcrumb={false}>
           <Fubilizer/>
        </Layout>
    )
}