import StepButtons from "./StepButtons";
import TraitRadioGroupRow from "../../../lib/radioGroup/TraitRadioGroupRow";
import IStep from "./interfaces/IStep";
import { useAudience } from "../../context/AudienceContext";
import { useState } from "react";
import ChevronDown from "../../../../assets/icons/icon-chevron-down.svg"

export default function AttitudesStep({ audienceName, onBack, onNext }: IStep) {
  const { traits, traitSections } = useAudience();
  const [selectedGroup, setSelectedGroup] = useState(-1);

  if (!traitSections) return <div></div>;

  return (
    <div>
      <div className="margin__bottom--s">
        <h2 className="margin__bottom--s">Editing audience: {audienceName}</h2>
        {/* <SampleSize /> */}
        <p className="margin__bottom--xxs">
          Here are some attitudes your audience might have. Click on a category to reveal the attitudes within it.
          Select up to three attitudes with the checkboxes on the left, and then indicate whether your audience has a positive or negative attitude.
        </p>
        <div className="create-audiences__scroll-area">
          <div className="box">
            {
              traitSections.map((section, i) => {
                return <div className="margin__bottom--xs" style={{ cursor: "pointer" }}>
                  <h3 className="margin__bottom--xxs" style={{ display: "flex", alignItems: "center", userSelect: "none" }} onClick={() => selectedGroup !== i ? setSelectedGroup(i) : setSelectedGroup(-1)}>
                    <img className={`side-menu__item__icon`} src={ChevronDown} style={{ transform: selectedGroup !== i ? "rotate(-90deg)": "", "display": "inline-block" }} />
                    {section}
                  </h3>
                  <div>{
                    selectedGroup === i && traits.filter(trait => trait.grouping === i).map((foundTrait, j) => {
                      return <TraitRadioGroupRow
                        key={`trait-${100 * i + j}`}
                        positiveLabel={foundTrait.positiveText}
                        negativeLabel={foundTrait.negativeText}
                        groupName={foundTrait.groupName}
                        value={foundTrait.selected} />
                    })
                  }</div>
                </div>;
              })
            }
          </div>
        </div>
      </div>
      <StepButtons onBack={onBack} onNext={onNext} skipShown />
    </div>
  );
}