 import InputCheckbox from "../../../lib/inputs/InputCheckbox";
import {useAudience} from "../../context/AudienceContext";
import StepButtons from "./StepButtons";
import IStepAreas from "./interfaces/IStepAreas";

export default function SelectContinentStep({onBack, onNext }: IStepAreas) {
    const {newAudienceName, continents, onContinentSelected, continentSelected} = useAudience();

    return (
        <div>
            <div className="margin__bottom--s">
                <h2 className="margin__bottom--s">Editing audience: {newAudienceName}</h2>
                <p className="margin__bottom--s">
                    Which continents does this audience live in? You will be able to select more specific countries and regions in the next steps.
                </p>
                {
                    continents && continents.map((area: any, index: number) => {
                        return (
                            <div className="box margin__bottom--xs" key={`${index}-continent`}>
                                <InputCheckbox
                                    className="left-align"
                                    label={area.name}
                                    value={""}
                                    checked={area.checked}
                                    onChangeCheckBox={(e: React.ChangeEvent<HTMLInputElement>) => onContinentSelected(area, e.target.checked)}/>
                            </div>
                        );
                    })
                }
            </div>
            <StepButtons onBack={onBack} onNext={onNext} nextDisabled={!continentSelected} skipShown />
        </div>
    );
}