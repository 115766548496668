import {ICheckboxListItem} from "../interfaces/ICheckboxListItem";

export function mapHomeOwnerships(mappings: any) {
    return mappings.map((m: any) => {
        return {
            label: m.text,
            value: m.value,
            checked: true
        } as ICheckboxListItem
    });
}

export function mapHomeOwnershipsFromSelected(selectedAudience: any, homeOwnerships: ICheckboxListItem[]) {
    var tempHomeOwnerships = [...homeOwnerships];
    var statuses = selectedAudience.questionnaire.demographic.homeOwnership;

    tempHomeOwnerships.forEach((g) => g.checked = false);

    for(var i = 0; i < statuses.length; i++) {
        var homeOwnership = tempHomeOwnerships.find((m) => m.value === statuses[i]);
        if(homeOwnership !== undefined) {
            homeOwnership.checked = true;
        }
    }
    return tempHomeOwnerships;
}