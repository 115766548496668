import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {Route, Routes} from "react-router-dom";
import HomePage from './components/app/pages/HomePage';
import {
    Heading,
    Image,
    Link,
    Text,
    useAuthenticator,
    useTheme,
    View,
    withAuthenticator
} from "@aws-amplify/ui-react";
import {API} from "@aws-amplify/api";
import {Auth} from "@aws-amplify/auth";
import Logo from '../src/assets/images/fubilizer-logo-gradient.svg';
import React, { useEffect, useState } from "react";
import AudiencesPage from "./components/app/pages/AudiencesPage";
import {AudienceProvider} from "./components/app/context/AudienceContext";
import AudiencePage from "./components/app/pages/AudiencePage";
import * as Navigation from "./modules/Navigation";
import Button from './components/lib/buttons/Button';
import Terms from './components/lib/terms/terms';

function App() {
    API.configure({
        endpoints: [
            {
                name: "API",
                endpoint: "https://j85np5uj19.execute-api.eu-west-2.amazonaws.com/api",
                custom_header: async () => ({
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${(await Auth.currentSession())
                        .getIdToken()
                        .getJwtToken()}`,
                }),
            },
        ],
    });

    const [loading, setLoading] = useState(true);
    const [hasAcceptedTerms, setHasAcceptedTerms] = useState(true);

    useEffect(() => {
        setLoading(false);
        // API.get('API', '/auth/me', {}).then(response => {
        //     setHasAcceptedTerms(response.termsAccepted || false);
        //     setLoading(false);
        //   });
    }, []);

    async function onReject() {
        await Auth.signOut();
        window.location.href = "https://fubi.ai/";
    }

    function onAccept() {
        setHasAcceptedTerms(true);
        API.post('API', '/auth/accept-terms', { body: {} });
    }

    const theTerms = (
        <div style={{"display": "flex", flexDirection: "column", padding:"1rem", gap: "1rem", height:"100vh"}}>
            <header>
                <h1>Welcome to the Fubilizer</h1>
                <p>Please read through the terms and conditions thoroughly before getting started.</p>
            </header>

            <div style={{ flex:"1 0 10vh", borderRadius: "10px", "border" : "2px solid orange", overflowY: "scroll" }}>
                <Terms />
            </div>

            <footer style={{display: "flex", justifyContent: "flex-end", gap: "20px"}}>
                <Button
                    text={"Reject"}
                    className={""}
                    type={"secondary"}
                    destructive={true}
                    outline={true}
                    size={"large"}
                    disabled={false}
                    onClick={onReject}
                    children={null}
                />

                <Button
                    text={"Accept"}
                    className={""}
                    type={"primary"}
                    destructive={false}
                    outline={false}
                    size={"large"}
                    disabled={false}
                    onClick={onAccept}
                    children={null}
                />
            </footer>
        </div>
    );

    const theApp = (<AudienceProvider>
           <ToastContainer autoClose={3000} />
           <Routes>
             <Route path={Navigation.root.path} element={<HomePage />} />
            <Route path={Navigation.audiences.path} element={<AudiencesPage />} />
             <Route path={Navigation.audience.path} element={<AudiencePage />} />
           </Routes>
         </AudienceProvider>);

    return (loading ? <></> : (hasAcceptedTerms ? theApp : theTerms));
}

const components = {
    Header() {
        const { tokens } = useTheme();

        return (
            <View textAlign="center" padding={tokens.space.large}>
                <Image
                    alt="Fubilizer logo"
                    src={Logo}
                    className={"amplify__logo"}
                />
            </View>
        );
    },

    Footer() {
        const { tokens } = useTheme();

        return (
            <View textAlign="center" padding={tokens.space.large}>
                <Text color={tokens.colors.neutral[80]}>
                    &copy; All Rights Reserved
                </Text>
            </View>
        );
    },
    SignIn: {
        Header() {
            const { tokens } = useTheme();

            return (
                <Heading
                    padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
                    level={3}
                    textAlign={"left"}
                    fontFamily={"Mont"}
                    fontWeight={"bold"}
                    fontSize={"21px"}
                >
                    Login
                </Heading>
            );
        },
        Footer() {
            const { toResetPassword } = useAuthenticator();

            return (
                <View textAlign="left">
                    <Link onClick={toResetPassword} className="amplify__reset-password">Forgot Password?</Link>
                </View>
            );
        },
    },
}

const formFields = {
    signIn: {
        username: {
            placeholder: "Enter your username"
        },
        password: {
            placeholder: "Enter your password"
        }
    }
}

export default withAuthenticator(App, {components: components, hideSignUp: true, formFields: formFields});

