import InputCheckbox from "../../../../lib/inputs/InputCheckbox";
import {useAudience} from "../../../context/AudienceContext";
import {ICheckboxListItem} from "../../interfaces/ICheckboxListItem";

export default function GenderSelector() {
    const {genders, updateGenders} = useAudience();

    return <div className="box margin__bottom--s">
        <p className="margin__bottom--s">Gender</p>
        <div className="create-audiences__checkbox-grid">
            {
                genders && genders.map((gender: ICheckboxListItem, index: number) => {
                    return <InputCheckbox
                        key={`${index}-gender`}
                        className="left-align"
                        label={gender.label}
                        checked={gender.checked}
                        value={""}
                        onChangeCheckBox={() => updateGenders(gender)}
                    />
                })
            }
        </div>
    </div>
}