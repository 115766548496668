import Button from "../../lib/buttons/Button";
import React, {useEffect, useState} from "react";
import AudiencesListItem from "./AudiencesListItem";
import {useAudience} from "../context/AudienceContext";
import CreateAudience from "./CreateAudience";
import Modal from "../layout/Modal";

export default function AudiencesList() {
    const {audiences, resetData} = useAudience();
    const [showModal, setShowModal] = useState(false)

    function createNew() {
        document.body.classList.toggle('scroll--hidden')
        setShowModal(true)
    }

    function onModalClose() {
        document.body.classList.toggle('scroll--hidden')
        resetData();
        setShowModal(false);
    }

    useEffect(() => {

    }, [audiences])

    return (
        <div className="audiences__layout">
            <div className="audiences__header">
                <h3 className="audiences__header-title">My Custom Audiences</h3>
                <Button
                    text={"+ Create new"}
                    className={"audiences__create"}
                    type={"secondary"}
                    destructive={false}
                    outline={false}
                    size={"small"}
                    disabled={false}
                    onClick={() => createNew()}
                    children={null}
                />
            </div>
            {audiences && audiences.length > 0 &&
                <div className="audiences__list">
                    {audiences.map((audience: any) => {
                            return <AudiencesListItem audience={audience} key={audience.id}/>
                        })
                    }
                </div>
            }
            {audiences?.length === 0 &&
                <div className="audiences__list--empty">
                    <p>You have no custom audiences</p>
                </div>
            }
            <Modal isOpen={showModal} modalClosed={() => onModalClose()} children={<CreateAudience onComplete={() => setShowModal(false)} />} />
        </div>
    )
}