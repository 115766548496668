import MultiRangeSlider, {ChangeResult} from "multi-range-slider-react";
import {useAudience} from "../../../context/AudienceContext";

export default function AgeRangeSelector() {
    const ageRangeLabels = ["0", "10", "20", "30", "40", "50", "60", "70", "80", "90", "100"]

    const {ageRange, updateAgeRange} = useAudience();
    return <div className="margin__bottom--m">
        <p className="margin__bottom--s">Age range</p>
        <MultiRangeSlider
            labels={ageRangeLabels}
            min={0}
            max={100}
            step={10}
            minValue={ageRange.minimum}
            maxValue={ageRange.maximum}
            onInput={(e: ChangeResult) => updateAgeRange(e.minValue, e.maxValue)}
        />
    </div>
}