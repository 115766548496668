import InputCheckbox from "../inputs/InputCheckbox";
import InputRadio from "../inputs/InputRadio";
import {TraitConnotation} from "../../app/audiences/steps/enums/TraitConnotation";
import {useAudience} from "../../app/context/AudienceContext";

interface IRadioGroupRow{
    positiveLabel: string;
    negativeLabel: string;
    groupName: number;
    value: TraitConnotation;
}
export default function TraitRadioGroupRow({positiveLabel, negativeLabel, groupName, value }:IRadioGroupRow){
    const{updateTraits, canAddMoreTraits} = useAudience();

    function handleCheckBox(e: React.ChangeEvent<HTMLInputElement>) {
        var isSelected = e.target.checked;
        updateTraits(groupName, isSelected ? TraitConnotation.NA : TraitConnotation.Disabled);
    }

    function handleRadioChange(value: TraitConnotation){
        updateTraits(groupName, value);
    }

    return(
        <div className="create-audiences__statement-radios">
            <InputCheckbox value=""
                           checked={value !== TraitConnotation.Disabled}
                           onChangeCheckBox={handleCheckBox}
                            disabled={value === TraitConnotation.Disabled && !canAddMoreTraits}/>
            <div className="create-audiences__radio-grid">
                <InputRadio name={groupName.toString()}
                            value={positiveLabel}
                            label={positiveLabel}
                            checked={value === TraitConnotation.Positive}
                            onChangeCheckBox={() => handleRadioChange(TraitConnotation.Positive)}
                            disabled={value === TraitConnotation.Disabled}/>
                <InputRadio name={groupName.toString()}
                            value={negativeLabel}
                            label={negativeLabel}
                            checked={value === TraitConnotation.Negative}
                            onChangeCheckBox={() => handleRadioChange(TraitConnotation.Negative)}
                            disabled={value === TraitConnotation.Disabled}/>
            </div>
        </div>

    );
}