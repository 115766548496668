import ReactModal from "react-modal";
import React, {useEffect, useState} from "react";

interface IModal {
    isOpen: boolean,
    children: string | JSX.Element | JSX.Element[],
    modalClosed: Function,
    whiteBackground?: boolean
    hideCancel?: boolean
    cancelLabel?: string,
    size?: "normal" | "full",
}

const customStyles = {
    overlay: {
        zIndex: 1000,
        backgroundColor: 'rgba(1, 1, 1, 0.25)'
    },
  };

const customStylesWhite = {
    overlay: {
        zIndex: 1000,
        backgroundColor: 'rgba(0,0,0)'
    },
};

export default function Modal({isOpen, children, modalClosed, whiteBackground = false, hideCancel = false, cancelLabel = "Cancel", size = "normal"}: IModal) {
    const [open, setOpen] = useState(false);

    useEffect(() => {
        setOpen(isOpen);
    }, [isOpen]);

    function closeModal() {
        setOpen(false);
        modalClosed();
    }

    const modalSizeClass = size === "full" ? "modal--wide" : hideCancel ? "modal--small" : "";
 
    return (<ReactModal isOpen={open}
                appElement={document.getElementById('root') as HTMLElement}
                contentLabel="Minimal Modal Example"
                className={`modal ${modalSizeClass}`}
                shouldCloseOnOverlayClick={false}
                style={whiteBackground ? customStylesWhite : customStyles}
                onRequestClose={() => closeModal()}>
                {!hideCancel &&
                    <div className="display--flex justifyContent--flex-end margin__bottom--xs">
                        <button className="button" onClick={() => closeModal()}>{cancelLabel}</button>
                    </div>
                }
                {children}
    </ReactModal>);
}