import InputCheckbox from "../../../../lib/inputs/InputCheckbox";
import {useAudience} from "../../../context/AudienceContext";
import {ICheckboxListItem} from "../../interfaces/ICheckboxListItem";

export default function MaritalStatusSelector() {
    const {professions, updateProfessions} = useAudience();

    return <div className="margin__bottom--m">
        <p className="margin__bottom--s">Profession</p>
        <div className="create-audiences__checkbox-grid">
            {
                professions && professions.map((profession: ICheckboxListItem, index: number) => {
                    return <InputCheckbox
                        key={`marital-status-${index}`}
                        className="left-align"
                        label={profession.label}
                        checked={profession.checked}
                        value={""}
                        onChangeCheckBox={() => updateProfessions(profession)}
                    />
                })
            }
        </div>
    </div>
}