import React, {useState} from 'react';

export interface IInputTextArea {
    className?: string,
	label?: string,
	name?: string,
	required?: boolean,
	validateRule?: Function,
	validationError?: string,
	icon?:any,
	onChangeText: Function,
	[other: string | number | symbol]: unknown;
}

export default function InputTextArea({className, label, name, required, validateRule, validationError,onChangeText, ...other}:IInputTextArea) {
	const [valid, setValid] = useState();

	function validate(e:React.ChangeEvent<HTMLTextAreaElement>){
		if(validateRule){
			setValid(validateRule((e.target as HTMLTextAreaElement).value))
		}
	}

	function handleChange(e:React.ChangeEvent<HTMLTextAreaElement>){
		if(onChangeText){
			onChangeText(e)
		}
	}

    return <div className={`${className ? className : ''}`}>
        <label className="input-text__label" htmlFor={name}>
			{required ? `${label} *` : label}
		</label>
		<div className={`input-text__wrapper `}>
			<textarea  className={`input-text input-text-area input-text--full display--block ${valid === false ? "input-text--error" : ""}`}  
				name={name} 
				required={required} 
				onBlur={validate}
				onChange={handleChange}
				{...other} />
		</div>
		

        { valid === false && validationError !== "" &&
			<p className="xsmall error">{validationError}</p>
        }
	</div>
}
