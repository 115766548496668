import StepButtons from "./StepButtons";
import {useAudience} from "../../context/AudienceContext";
import AudienceCard from "../AudienceCard";
import {useState} from "react";
import {IAudience} from "../interfaces/IAudience";

interface IStepSelectTemplate {
    onBack: Function,
    onNext: Function
}

export default function StepSelectTemplate({onBack, onNext}: IStepSelectTemplate) {
    const {audiences, selectAudienceTemplate} = useAudience();
    const [selectedAudience, setSelectedAudience] = useState<IAudience | null>(null);

    function selectNext() {
        if(selectedAudience != null) {
            selectAudienceTemplate(selectedAudience.name);
            onNext();
        }
    }

    return <div>
        <div className="margin__bottom--xs">
            <h2 className="margin__bottom--s step-select-template__title">Select an audience template</h2>
            <div className="step-select-template__box">
                {
                    audiences.map((audience, index) => {
                        return <div key={`audience-${index}`} className={`box ${selectedAudience === audience ? "box--active" : ""}`}>
                            <AudienceCard elementKey={index}
                                          selected={selectedAudience === audience}
                                          audience={audience}
                                          onSelect={() => setSelectedAudience(audience)}
                                          preventSelectedHighlighting={true}/>
                        </div>;
                    })
                }
            </div>
        </div>
        <StepButtons onBack={onBack} onNext={selectNext} nextDisabled={selectedAudience === null}/>
    </div>
}