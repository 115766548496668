import {TraitConnotation} from "../steps/enums/TraitConnotation";
import {ITrait} from "../interfaces/ITrait";

export function mapTraits(mappings: any) {
    return mappings.map((m: any) => {
        return {
            groupName: m.value,
            positiveText: m.positiveText,
            negativeText: m.negativeText,
            selected: TraitConnotation.Disabled,
            grouping: m.group,
        } as ITrait;
    });
}

export function mapTraitsFromSelected(selectedAudience: any, traits: ITrait[]) {
    var tempTraits = [...traits];
    var positives = selectedAudience.questionnaire.attitudes.positive;
    var negative = selectedAudience.questionnaire.attitudes.negative;

    tempTraits.forEach((t) => {
        t.selected = TraitConnotation.Disabled;
    });

    for(var i = 0; i < positives.length; i++) {
        var trait = tempTraits.find((t) => t.groupName === positives[i]);
        if(trait !== undefined) {
            trait.selected = TraitConnotation.Positive;
        }
    }

    for(var i = 0; i < negative.length; i++) {
        var trait = tempTraits.find((t) => t.groupName === negative[i]);
        if(trait !== undefined) {
            trait.selected = TraitConnotation.Negative;
        }
    }

    return tempTraits;
}

export function mapTraitSections(mappings: any) {
  return mappings;
}