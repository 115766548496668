import {ICheckboxListItem} from "../interfaces/ICheckboxListItem";

export function mapInterests(mappings: any) {
    return mappings.map((m: any) => {
        return {
            label: m.text,
            value: m.value,
            checked: false
        } as ICheckboxListItem
    });
}

export function mapAreasOfInterestFromSelected(selectedAudience: any, areasOfInterest: ICheckboxListItem[]) {
    var tempAreasOfInterest = [...areasOfInterest];
    var interests = selectedAudience.questionnaire.interests;

    tempAreasOfInterest.forEach((a) => {
        a.checked = false;
    });

    for(var i = 0; i < interests.length; i++) {
        var areaOfInterest = tempAreasOfInterest.find((a) => a.value === interests[i]);
        if(areaOfInterest !== undefined) {
            areaOfInterest.checked = true;
        }
    }

    return tempAreasOfInterest;
}