import { ICheckboxListItem } from "../interfaces/ICheckboxListItem";

export function mapProfessions(mappings: any) {
  return mappings.map((m: any) => {
    return {
      label: m.text,
      value: m.value,
      checked: true
    } as ICheckboxListItem
  });
}

export function mapProfessionsFromSelected(selectedAudience: any, professions: ICheckboxListItem[]) {
  var tempProfessions = [...professions];
  var statuses = selectedAudience.questionnaire.demographic.professions;

  tempProfessions.forEach((g) => g.checked = false);

  if (statuses) {
    for (var i = 0; i < statuses.length; i++) {
      var profession = tempProfessions.find((m) => m.value === statuses[i]);
      if (profession !== undefined) {
        profession.checked = true;
      }
    }
  }

  return tempProfessions;
}