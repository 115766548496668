import {IAudience} from "./interfaces/IAudience";
import AudienceIcon from "../../../assets/icons/icon-audience.svg";
import {useAudience} from "../context/AudienceContext";
import {useEffect, useState} from "react";
import IconTickOrange from "../../../assets/icons/icon-tick-orange.svg";
import IconRedCross from "../../../assets/icons/icon-red-cross.svg";
import IconOrangeHeart from "../../../assets/icons/icon-orange-heart.svg";
import Button from "../../lib/buttons/Button";
import IconBin from "../../../assets/icons/icon-bin.svg";
import IconAlert from "../../../assets/icons/icon-alert.svg";
import * as Navigation from "../../../modules/Navigation";
import {useNavigate} from "react-router-dom";
import Modal from "../layout/Modal";

interface IAudienceDetailProps {
    id: string;
}

export default function AudienceDetail({id}: IAudienceDetailProps) {
    const navigate = useNavigate();
    const {getUrbanByValue,
            audiences,
            deleteAudience,
            getGenderLabelByValue,
            getHomeOwnershipByValue,
            getMaritalStatusByValue,
            getEducationLabelsByMinMax,
            getProfessionByValue,
            getPositiveTraitLabelsByIndex,
            getNegativeTraitLabelsByIndex,
            getCountryTagsByAudienceId,
    } = useAudience();

    const [urbanValues, setUrbanValues] = useState<string[]>([]);
    const [ageRange, setAgeRange] = useState<string[]>([]);
    const [genders, setGenders] = useState<string[]>([]);
    const [homeOwnerships, setHomeOwnerships] = useState<string[]>([]);
    const [maritalStatus, setMaritalStatus] = useState<string[]>([]);
    const [educations, setEducations] = useState<string[]>([]);
    const [professions, setProfessions] = useState<string[]>([]);
    const [audience, setAudience] = useState<any | undefined>();
    const [positives, setPositives] = useState<string[]>([]);
    const [negatives, setNegatives] = useState<string[]>([]);
    const [locationTags, setLocationTags] = useState<string[]>([]);
    const [showDeleteModal, setShowDeleteModal] = useState(false);


    useEffect(() => {
        var found = audiences.find((audience: IAudience) => audience.id.toString() === id)
        setAudience(found);
        setLocationTags(getCountryTagsByAudienceId(id.toString()).sort());
        getData(found);
    }, [audiences, id])

    function getData(currentAudience: any) {
        setUrbanValues(currentAudience?.questionnaire?.demographic?.urban.map((urban: string) => getUrbanByValue(urban)));
        setAgeRange([`${currentAudience?.questionnaire?.demographic?.age[0]} - ${currentAudience?.questionnaire?.demographic?.age[1]}`]);
        setGenders(currentAudience?.questionnaire?.demographic?.sex.map((gender: string) => getGenderLabelByValue(gender)));
        setHomeOwnerships(currentAudience?.questionnaire?.demographic?.homeOwnership.map((homeOwnership: string) => getHomeOwnershipByValue(homeOwnership)));
        setMaritalStatus(currentAudience?.questionnaire?.demographic?.marital.map((marital: string) => getMaritalStatusByValue(marital)));
        setEducations(getEducationLabelsByMinMax(currentAudience?.questionnaire?.demographic?.education[0], currentAudience?.questionnaire?.demographic?.education[1]));
        setProfessions(currentAudience?.questionnaire?.demographic?.professions?.map((profession: string) => getProfessionByValue(profession)) || []);
        setPositives(getPositiveTraitLabelsByIndex(currentAudience?.questionnaire?.attitudes?.positive));
        setNegatives(getNegativeTraitLabelsByIndex(currentAudience?.questionnaire?.attitudes?.negative));
    }

    async function onDelete() {
        setShowDeleteModal(false);
        var result = await deleteAudience(id);
        if(result) {
            navigate(Navigation.audiences.to);
        }
    }

    function onModalClose() {
        setShowDeleteModal(false);
    }

    return <div>
        {
            audience &&
            <>
                <div className="audience__header">
                    <div className="audience__header-title">
                        <img src={AudienceIcon} alt="Audience Icon"/>
                        <h2>{audience.name}</h2>
                    </div>
                    <Button
                        text={"Delete"}
                        className={""}
                        type={"text-only"}
                        destructive={false}
                        outline={false}
                        size={"small"}
                        disabled={false}
                        onClick={() => setShowDeleteModal(true)}
                        children={<img src={IconBin} alt="Delete Audience"/>}
                    />
                </div>
                <div className="audience__content">
                    <div className="audience__card--two-col grid-x">
                        <div className="cell small-12 large-6 margin__bottom--xs">
                            <h3 className="audience__card-title margin__bottom--xs">Demographic information</h3>
                            <div className="grid-x">
                                <DemographicValue label="Age range" values={ageRange} className="cell small-12 large-6"/>
                                <DemographicValue label="Urban or Suburban" values={urbanValues} className="cell small-12 large-6"/>
                                <DemographicValue label="Gender(s)" values={genders} className="cell small-12 large-6"/>
                                <DemographicValue label="Housing status" values={homeOwnerships} className="cell small-12 large-6"/>
                                <DemographicValue label="Marital status" values={maritalStatus} className="cell small-12 large-6"/>
                                <DemographicValue label="Education" values={educations} className="cell small-12 large-6"/>
                                <DemographicValue label="Profession" values={professions} className="cell small-12 large-6"/>
                            </div>
                        </div>
                        <div className="cell small-12 large-6">
                            <h3 className="audience__card-title margin__bottom--xs">Location</h3>
                            <div className="audience__value-wrapper">
                                {
                                    locationTags && locationTags.map((tag: string, index: number) => {
                                        return <div className="audience__tag" key={`location-${index}`}>
                                            <p className="small">{tag}</p>
                                        </div>
                                    })
                                }
                            </div>
                        </div>
                    </div>

                    <div className="audience__card grid-x">
                        <h3 className="audience__card-title margin__bottom--xs">Personality</h3>
                        <div className="grid-x audience__card-section">
                            <div className="cell small-12 large-6">
                                <Attitudes positives={positives} positive={true}/>
                            </div>

                            <div className="cell small-12 large-6">
                                <Attitudes positives={negatives} positive={false}/>
                            </div>
                        </div>
                    </div>
                    <div className="audience__card grid-x">
                        <h3 className="audience__card-title margin__bottom--xs">Hobbies & Interests</h3>
                        <Interests interests={audience?.questionnaire?.interests as number[]}/>
                    </div>
                </div>
            </>
        }
        <Modal hideCancel={true}
               isOpen={showDeleteModal}
               modalClosed={() => onModalClose()}
               children={<DeleteConfirmationModal id={id} onCancel={() => setShowDeleteModal(false)} onConfirm={onDelete}/>} />
    </div>
}

function Attitudes({positives: attitudes, positive: boolean}: any) {
    return <div className="audience__personality-list">
        {
            attitudes.map((attitude: string, index: number) => {
                let isPositive = !!boolean;
                return <div className="audience__trait" key={`positive-${index}`}>
                        {
                            isPositive && <img src={IconTickOrange} alt="Positive Attitude"/>
                        }
                        {
                            !isPositive && <img src={IconRedCross} alt="Negative Attitude"/>
                        }
                        <p className="small">{attitude}</p>
                    </div>
                }
            )
        }
    </div>
}

interface IInterestsCard {
    interests: number[];
}

function Interests({interests}: IInterestsCard) {
    const {getInterestsByValue} = useAudience();

    return <div className="audience__personality-list">
        {
            interests.map((interest: number, index: number) => {
                return <div className="audience__trait" key={`interest-${index}`}>
                    <img src={IconOrangeHeart} alt="Tick Icon"/>
                    <p className="small">{getInterestsByValue(interest)}</p>
                </div>
            })
        }
    </div>
}

function DemographicValue({label, values, className}: any) {
    return <div className={`margin__bottom--xs ${className !== undefined ? className : ""}`}>
        <p className="audience__demographic-label margin__bottom--xs">{label}</p>
        <div className="audience__value-wrapper">
            {
                values.map((value: string, index: number) => {
                    return <div className="audience__tag" key={`${label}-${index}`}>
                        <p className="small">{value}</p>
                    </div>
                })
            }
        </div>
    </div>
}

function DeleteConfirmationModal({onCancel, onConfirm}: any) {
    return <div>
        <div className="audience-delete-modal">
            <img src={IconAlert} alt="Alert Icon" className="audience-delete-modal__icon"/>
            <h3>Delete</h3>
            <p>Are you sure you wish to delete this audience?</p>
        </div>
        <div className="audience-delete-modal__button-wrapper">
            <Button
                text={"Cancel"}
                className={""}
                type={"tertiary"}
                destructive={false}
                outline={false}
                size={"large"}
                disabled={false}
                onClick={onCancel}
                children={null}
            />
            <Button
                text={"Confirm"}
                className={""}
                type={"secondary"}
                destructive={false}
                outline={false}
                size={"large"}
                disabled={false}
                onClick={onConfirm}
                children={null}
            />

        </div>
    </div>
}