export interface INavigationElement{
    title: string;
    path: string;
    to: string;
    navigate: Function
}

export function navigateHome() {
    root.navigate();
}

export const root: INavigationElement = {
    title: "Fubilize",
    path: '/',
    to: '/',
    navigate: (navigateFnc:Function) => { navigateFnc('/') }
};

export const termsAndConditions: INavigationElement = {
    title: "Fubilize",
    path: '/terms-and-conditions',
    to: '/terms-and-conditions',
    navigate: (navigateFnc:Function) => { navigateFnc('/terms-and-conditions') }
};

export const audiences: INavigationElement = {
    title: "Audiences",
    path: '/audiences',
    to: '/audiences',
    navigate: (navigateFnc:Function) => { navigateFnc('/audiences') }
};

export const audience: INavigationElement = {
    title: "Audience",
    path: '/audience/:id',
    to: '/audience',
    navigate: (navigateFnc:Function, id: number) => { navigateFnc(`/audience/{id}`) }
};

export const guides: INavigationElement = {
    title: "Guides & Inspo",
    path: '/guides',
    to: '/guides',
    navigate: (navigateFnc:Function) => { navigateFnc('/') }
};

export const administration: INavigationElement = {
    title: "Administration",
    path: '/administration',
    to: '/administration',
    navigate: (navigateFnc:Function) => { navigateFnc('/') }
};

export const analytics: INavigationElement = {
    title: "Analytics",
    path: '/analytics',
    to: '/analytics',
    navigate: (navigateFnc:Function) => { navigateFnc('/') }
};

