import InputCheckbox from "../inputs/InputCheckbox";
import {ICheckboxListItem} from "../../app/audiences/interfaces/ICheckboxListItem";

interface ICheckboxList{
    items: ICheckboxListItem[];
    onChange: Function;
    reachedMaximum?: boolean;
}

export default function CheckboxList({items, onChange, reachedMaximum}: ICheckboxList){
    return(
        <div className="create-audiences__checkbox-list">
            {items.map((item, index) => {
                return (
                    <InputCheckbox
                        className="left-align"
                        label={item.label}
                        checked={item.checked}
                        disabled={!reachedMaximum && !item.checked}
                        value={""}
                        key={index}
                        onChangeCheckBox={(e: React.ChangeEvent<HTMLInputElement>) => onChange(item.label)}
                    />
                );
            })}
        </div>
    );
}