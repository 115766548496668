import { createContext, useContext, useEffect, useState } from "react";
import { ITrait } from "../audiences/interfaces/ITrait";
import { TraitConnotation } from "../audiences/steps/enums/TraitConnotation";
import { ICheckboxListItem } from "../audiences/interfaces/ICheckboxListItem";
import { API } from "@aws-amplify/api";
import { toast } from "react-toastify";
import IRange from "../audiences/interfaces/IRange";
import IAudienceContext from "../audiences/interfaces/IAudienceContext";
import { IEducation } from "../audiences/interfaces/IEducation";
import { IAudience } from "../audiences/interfaces/IAudience";
import { IDefaultAudience } from "../audiences/interfaces/IDefaultAudience";
import { IContinent } from "../audiences/interfaces/IContinent";
import { ICountry } from "../audiences/interfaces/ICountry";
import { IRegion } from "../audiences/interfaces/IRegion";
import { mapGenders, mapGendersFromSelected } from "../audiences/mappers/GenderMapper";
import { mapMaritalStatuses, mapMaritalStatusesFromSelected } from "../audiences/mappers/MaritalStatusMapper";
import { mapHomeOwnerships, mapHomeOwnershipsFromSelected } from "../audiences/mappers/HomeOwnershipMapper";
import { mapProfessions, mapProfessionsFromSelected } from "../audiences/mappers/ProfessionMapper";
import { mapEnvironmentFromSelected, mapUrban } from "../audiences/mappers/UrbanMapper";
import { mapAgeRange, mapAgeRangeFromSelected } from "../audiences/mappers/AgeRangeMapper";
import { mapEducationFromSelected, mapEducations } from "../audiences/mappers/EducationMapper";
import { mapTraits, mapTraitsFromSelected, mapTraitSections } from "../audiences/mappers/TraitMapper";
import { mapAreasOfInterestFromSelected, mapInterests } from "../audiences/mappers/InterestMapper";
import { mapContinents, mapRegionFromCountryAndValue, mapRegionsFromSelected } from "../audiences/mappers/LocationMapper";
import IconToastError from "../../../assets/icons/icon-toast-error.svg";
import IconSparkleGradient from "../../../assets/icons/icon-sparkle-gradient.svg";
import IconToastSuccess from "../../../assets/icons/icon-toast-success.svg";

interface IAudienceContextProps {

}

const AudienceContext = createContext<IAudienceContext>({} as IAudienceContext);

function AudienceProvider({ ...props }: IAudienceContextProps) {
  //#region State
  const [newAudienceName, setNewAudienceName] = useState<string>('');
  const [customPrompt, setCustomPrompt] = useState<string>('');
  const [audiences, setAudiences] = useState<IAudience[]>([]);
  const [defaultAudiences, setDefaultAudiences] = useState<IDefaultAudience[]>([]);
  const [traits, setTraits] = useState<ITrait[]>([]);
  const [traitSections, setTraitSections] = useState<string[]>([]);
  const [areasOfInterest, setAreasOfInterest] = useState<ICheckboxListItem[]>([]);
  const [ageRange, setAgeRange] = useState<IRange>({ minimum: 15, maximum: 80 });
  const [educationRange, setEducationRange] = useState<IRange>({ minimum: 1, maximum: 3 });
  const [educations, setEducations] = useState<IEducation[]>([]);
  const [maritalStatuses, setMaritalStatuses] = useState<ICheckboxListItem[]>([]);
  const [genders, setGenders] = useState<ICheckboxListItem[]>([]);
  const [homeOwnerships, setHomeOwnerships] = useState<ICheckboxListItem[]>([]);
  const [professions, setProfessions] = useState<ICheckboxListItem[]>([]);
  const [environments, setEnvironments] = useState<ICheckboxListItem[]>([]);
  const [fromTemplate, setFromTemplate] = useState<boolean>(false);
  const [continents, setContinents] = useState<IContinent[]>([]);
  const [sampleSize, setSampleSize] = useState<number>(0);

  // A state for blocking the UI while the audiences are loading as this can
  // take a while after the database has gone to sleep
  const [firstLoad, setFirstLoad] = useState(true);
  //#endregion

  useEffect(() => {
    getAudiences();
    getDefaultAudiences();
    resetData();
  }, [])

  //#region Audience
  async function getMappings() {
    var mappingResponse = {
        "attitudeSections": [
            "Media and social engagement",
            "Lifestyle choices and personal expression",
            "Confidence, status, and change",
            "Values and personal goals"
        ],
        "attitudes": [
            {
                "positiveText": "Sceptical of branded content",
                "negativeText": "Brand friendly",
                "value": 1
            },
            {
                "positiveText": "Early adopter",
                "negativeText": "Late adopter",
                "value": 2,
                "group": 1
            },
            {
                "positiveText": "Time-poor",
                "negativeText": "Lots of free time",
                "value": 3,
                "group": 1
            },
            {
                "positiveText": "Driven by status",
                "negativeText": "Comfortable with their status",
                "value": 4,
                "group": 2
            },
            {
                "positiveText": "Lives a sustainable life",
                "negativeText": "Eco-sceptic",
                "value": 5,
                "group": 1
            },
            {
                "positiveText": "Influenced by social media",
                "negativeText": "Sceptical of social media",
                "value": 6,
                "group": 0
            },
            {
                "positiveText": "Prefers print media to online",
                "negativeText": "Prefers online media to print",
                "value": 7,
                "group": 0
            },
            {
                "positiveText": "Family-focused",
                "negativeText": "Self-focused",
                "value": 8,
                "group": 1
            },
            {
                "positiveText": "Consults with friends and family",
                "negativeText": "Self reliant",
                "value": 9,
                "group": 1
            },
            {
                "positiveText": "Socially conservative",
                "negativeText": "Socially liberal",
                "value": 10
            },
            {
                "positiveText": "Volunteers",
                "negativeText": "Does not volunteer",
                "value": 11,
                "group": 3
            },
            {
                "positiveText": "Thrives on feedback",
                "negativeText": "Chooses their own path",
                "value": 12,
                "group": 2
            },
            {
                "positiveText": "Shares interests online",
                "negativeText": "Undersharer online",
                "value": 13,
                "group": 0
            },
            {
                "positiveText": "Worries what people think",
                "negativeText": "Self confident",
                "value": 14,
                "group": 2
            },
            {
                "positiveText": "Heavily influenced by the Internet",
                "negativeText": "Sceptical of the Internet",
                "value": 15,
                "group": 2
            },
            {
                "positiveText": "Sceptical of online sources",
                "negativeText": "Trusts online sources",
                "value": 16,
                "group": 0
            },
            {
                "positiveText": "Networker",
                "negativeText": "Lone wolf",
                "value": 17,
                "group": 1
            },
            {
                "positiveText": "Keeps up with fashions",
                "negativeText": "Has own style",
                "value": 18,
                "group": 1
            },
            {
                "positiveText": "Comfortable expressing opinions online",
                "negativeText": "Reluctant to express opinions online",
                "value": 19,
                "group": 0
            },
            {
                "positiveText": "Overwhelmed by social change",
                "negativeText": "Thrives on social change",
                "value": 20,
                "group": 2
            },
            {
                "positiveText": "Activity organiser",
                "negativeText": "Activity participant",
                "value": 21,
                "group": 3
            },
            {
                "positiveText": "Ambitious and driven",
                "negativeText": "Relaxed and laid back",
                "value": 22,
                "group": 3
            },
            {
                "positiveText": "Politically conservative",
                "negativeText": "Politically liberal",
                "value": 23,
                "group": 3
            }
        ],
        "interests": [
            {
                "text": "Art, Culture, & Nightlife",
                "value": 1
            },
            {
                "text": "Banking & Investment",
                "value": 2
            },
            {
                "text": "Business",
                "value": 3
            },
            {
                "text": "Cars, Motorbikes, & Automobiles",
                "value": 4
            },
            {
                "text": "Charity, Altruism, & Campaigning",
                "value": 5
            },
            {
                "text": "Comics & Animation",
                "value": 6
            },
            {
                "text": "Cooking & Food",
                "value": 7
            },
            {
                "text": "Family-focused",
                "value": 8
            },
            {
                "text": "Fashion & Beauty",
                "value": 9
            },
            {
                "text": "Gadgets & Electronics",
                "value": 10
            },
            {
                "text": "Gaming",
                "value": 11
            },
            {
                "text": "Green Living",
                "value": 12
            },
            {
                "text": "Health & Fitness",
                "value": 13
            },
            {
                "text": "Home Improvement",
                "value": 14
            },
            {
                "text": "Memes, Jokes, & Laughs",
                "value": 15
            },
            {
                "text": "Movies",
                "value": 16
            },
            {
                "text": "Music",
                "value": 17
            },
            {
                "text": "News & Current Affairs",
                "value": 18
            },
            {
                "text": "Outdoors",
                "value": 19
            },
            {
                "text": "Pets",
                "value": 20
            },
            {
                "text": "Science & Technology",
                "value": 21
            },
            {
                "text": "Shopping",
                "value": 22
            },
            {
                "text": "Social Media",
                "value": 23
            },
            {
                "text": "Sports",
                "value": 24
            },
            {
                "text": "Thrills & Spills",
                "value": 25
            },
            {
                "text": "Travel",
                "value": 26
            },
            {
                "text": "TV",
                "value": 27
            }
        ],
        "locations": {
            "continentsMapping": [
                {
                    "name": "Americas",
                    "countries": [
                        "ARGENTINA",
                        "BRAZIL",
                        "CANADA",
                        "MEXICO",
                        "US"
                    ]
                },
                {
                    "name": "Asia, Middle East, Australasia",
                    "countries": [
                        "AUSTRALIA",
                        "CHINA",
                        "INDIA",
                        "INDONESIA",
                        "JAPAN",
                        "KOREA",
                        "RUSSIA",
                        "TURKEY"
                    ]
                },
                {
                    "name": "Europe",
                    "countries": [
                        "FRANCE",
                        "GERMANY",
                        "ITALY",
                        "NETHERLANDS",
                        "POLAND",
                        "SPAIN",
                        "SWEDEN",
                        "UK"
                    ]
                },
                {
                    "name": "africa",
                    "countries": [
                        "NIGERIA",
                        "SOUTHAFRICA"
                    ]
                }
            ],
            "countries": [
                {
                    "text": "Argentina",
                    "value": "ARGENTINA",
                    "regions": [
                        {
                            "text": "Buenos Aires",
                            "value": 1
                        },
                        {
                            "text": "Norte-Grande",
                            "value": 2
                        },
                        {
                            "text": "Centro",
                            "value": 3
                        },
                        {
                            "text": "Nuevo Cuyo",
                            "value": 4
                        },
                        {
                            "text": "Patagonia",
                            "value": 5
                        }
                    ]
                },
                {
                    "text": "Australia",
                    "value": "AUSTRALIA",
                    "regions": [
                        {
                            "text": "New South Wales",
                            "value": 1
                        },
                        {
                            "text": "Victoria",
                            "value": 2
                        },
                        {
                            "text": "Tasmania",
                            "value": 3
                        },
                        {
                            "text": "Queensland",
                            "value": 4
                        },
                        {
                            "text": "Northern Territory",
                            "value": 5
                        },
                        {
                            "text": "Western Australia",
                            "value": 6
                        },
                        {
                            "text": "South Australia",
                            "value": 7
                        }
                    ]
                },
                {
                    "text": "Brazil",
                    "value": "BRAZIL",
                    "regions": [
                        {
                            "text": "North",
                            "value": 1
                        },
                        {
                            "text": "North-east",
                            "value": 2
                        },
                        {
                            "text": "Central-west",
                            "value": 3
                        },
                        {
                            "text": "South-east",
                            "value": 4
                        },
                        {
                            "text": "South",
                            "value": 5
                        }
                    ]
                },
                {
                    "text": "Canada",
                    "value": "CANADA",
                    "regions": [
                        {
                            "text": "Quebec",
                            "value": 1
                        },
                        {
                            "text": "Atlantic Canada",
                            "value": 2
                        },
                        {
                            "text": "Ontario",
                            "value": 3
                        },
                        {
                            "text": "The North",
                            "value": 4
                        },
                        {
                            "text": "Western Canada",
                            "value": 5
                        }
                    ]
                },
                {
                    "text": "China",
                    "value": "CHINA",
                    "regions": [
                        {
                            "text": "Beijing",
                            "value": 1
                        },
                        {
                            "text": "Shanghai",
                            "value": 2
                        },
                        {
                            "text": "Guangzhou",
                            "value": 3
                        },
                        {
                            "text": "Wuhan",
                            "value": 4
                        },
                        {
                            "text": "Hangzhou",
                            "value": 5
                        },
                        {
                            "text": "Chengdu",
                            "value": 6
                        }
                    ]
                },
                {
                    "text": "France",
                    "value": "FRANCE",
                    "regions": [
                        {
                            "text": "SOUTH EAST (Auvergne-Rhône-Alpes, Provence-Alpes-Côte d'Azur)",
                            "value": 1
                        },
                        {
                            "text": "IDF (Île-de-France)",
                            "value": 2
                        },
                        {
                            "text": "SOUTH WEST (Occitanie, Nouvelle-Aquitaine)",
                            "value": 3
                        },
                        {
                            "text": "WEST (Centre-Val de Loire, Brittany, Pays de la Loire)",
                            "value": 4
                        },
                        {
                            "text": "NORTH (Normandy, Hauts-de-France)",
                            "value": 5
                        },
                        {
                            "text": "EAST (Grand Est, Bourgogne-Franche-Comté)",
                            "value": 6
                        }
                    ]
                },
                {
                    "text": "Germany",
                    "value": "GERMANY",
                    "regions": [
                        {
                            "text": "Hamburg",
                            "value": 1
                        },
                        {
                            "text": "Bremen",
                            "value": 2
                        },
                        {
                            "text": "Schleswig-Holstein",
                            "value": 3
                        },
                        {
                            "text": "Niedersachsen",
                            "value": 4
                        },
                        {
                            "text": "Nordrhein-Westfalen",
                            "value": 5
                        },
                        {
                            "text": "Hessen",
                            "value": 6
                        },
                        {
                            "text": "Rheinland-Pfalz",
                            "value": 7
                        },
                        {
                            "text": "Saarland",
                            "value": 8
                        },
                        {
                            "text": "Baden-Württemberg",
                            "value": 9
                        },
                        {
                            "text": "Bayern",
                            "value": 10
                        },
                        {
                            "text": "Berlin",
                            "value": 11
                        },
                        {
                            "text": "Brandenburg",
                            "value": 12
                        },
                        {
                            "text": "Sachsen-Anhalt",
                            "value": 13
                        },
                        {
                            "text": "Mecklenburg-Vorpommern",
                            "value": 14
                        },
                        {
                            "text": "Thüringen",
                            "value": 15
                        },
                        {
                            "text": "Sachsen",
                            "value": 16
                        }
                    ]
                },
                {
                    "text": "India",
                    "value": "INDIA",
                    "regions": [
                        {
                            "text": "Central India",
                            "value": 1
                        },
                        {
                            "text": "East India",
                            "value": 2
                        },
                        {
                            "text": "North India",
                            "value": 3
                        },
                        {
                            "text": "Northeast India",
                            "value": 4
                        },
                        {
                            "text": "South India",
                            "value": 5
                        },
                        {
                            "text": "Western India",
                            "value": 6
                        }
                    ]
                },
                {
                    "text": "Indonesia",
                    "value": "INDONESIA",
                    "regions": [
                        {
                            "text": "Java",
                            "value": 1
                        },
                        {
                            "text": "Sumatra",
                            "value": 2
                        },
                        {
                            "text": "Other regions",
                            "value": 3
                        }
                    ]
                },
                {
                    "text": "Italy",
                    "value": "ITALY",
                    "regions": [
                        {
                            "text": "Centre",
                            "value": 1
                        },
                        {
                            "text": "North West",
                            "value": 2
                        },
                        {
                            "text": "North East",
                            "value": 3
                        },
                        {
                            "text": "South & Islands",
                            "value": 4
                        }
                    ]
                },
                {
                    "text": "Japan",
                    "value": "JAPAN",
                    "regions": [
                        {
                            "text": "Hokkaido",
                            "value": 1
                        },
                        {
                            "text": "Tohoku",
                            "value": 2
                        },
                        {
                            "text": "Kanto",
                            "value": 3
                        },
                        {
                            "text": "Chubu",
                            "value": 4
                        },
                        {
                            "text": "Kansai",
                            "value": 5
                        },
                        {
                            "text": "Chugoku",
                            "value": 6
                        },
                        {
                            "text": "Shikoku",
                            "value": 7
                        },
                        {
                            "text": "Kyushu",
                            "value": 8
                        }
                    ]
                },
                {
                    "text": "South Korea",
                    "value": "KOREA",
                    "regions": [
                        {
                            "text": "Seoul",
                            "value": 1
                        },
                        {
                            "text": "Incheon",
                            "value": 2
                        },
                        {
                            "text": "Gyeonggi",
                            "value": 3
                        },
                        {
                            "text": "Gangwon",
                            "value": 4
                        },
                        {
                            "text": "South Gyeongsang",
                            "value": 5
                        },
                        {
                            "text": "North Gyeongsang",
                            "value": 6
                        },
                        {
                            "text": "Daegu",
                            "value": 7
                        },
                        {
                            "text": "Ulsan",
                            "value": 8
                        },
                        {
                            "text": "Busan",
                            "value": 9
                        },
                        {
                            "text": "South Jeolla",
                            "value": 10
                        },
                        {
                            "text": "North Jeolla",
                            "value": 11
                        },
                        {
                            "text": "Gwangju",
                            "value": 12
                        },
                        {
                            "text": "Jeju",
                            "value": 13
                        },
                        {
                            "text": "South Chungcheong",
                            "value": 14
                        },
                        {
                            "text": "North Chungcheong",
                            "value": 15
                        },
                        {
                            "text": "Daejeon",
                            "value": 16
                        }
                    ]
                },
                {
                    "text": "Mexico",
                    "value": "MEXICO",
                    "regions": [
                        {
                            "text": "Centre",
                            "value": 1
                        },
                        {
                            "text": "North",
                            "value": 2
                        },
                        {
                            "text": "Gulf",
                            "value": 3
                        },
                        {
                            "text": "Pacific",
                            "value": 4
                        },
                        {
                            "text": "South",
                            "value": 5
                        }
                    ]
                },
                {
                    "text": "Netherlands",
                    "value": "NETHERLANDS",
                    "regions": [
                        {
                            "text": "North",
                            "value": 1
                        },
                        {
                            "text": "East",
                            "value": 2
                        },
                        {
                            "text": "West",
                            "value": 3
                        },
                        {
                            "text": "South",
                            "value": 4
                        }
                    ]
                },
                {
                    "text": "Nigeria",
                    "value": "NIGERIA",
                    "regions": [
                        {
                            "text": "North Central",
                            "value": 1
                        },
                        {
                            "text": "North East",
                            "value": 2
                        },
                        {
                            "text": "North West",
                            "value": 3
                        },
                        {
                            "text": "South East",
                            "value": 4
                        },
                        {
                            "text": "South South",
                            "value": 5
                        },
                        {
                            "text": "South West",
                            "value": 6
                        }
                    ]
                },
                {
                    "text": "Poland",
                    "value": "POLAND",
                    "regions": [
                        {
                            "text": "Central Poland",
                            "value": 1
                        },
                        {
                            "text": "Southern Poland",
                            "value": 2
                        },
                        {
                            "text": "Northern Poland",
                            "value": 3
                        },
                        {
                            "text": "Western Poland",
                            "value": 4
                        },
                        {
                            "text": "Eastern Poland",
                            "value": 5
                        }
                    ]
                },
                {
                    "text": "Russia",
                    "value": "RUSSIA",
                    "regions": [
                        {
                            "text": "Central Federal District",
                            "value": 1
                        },
                        {
                            "text": "Northwestern Federal District",
                            "value": 2
                        },
                        {
                            "text": "Southern Federal District (from 2010)",
                            "value": 3
                        },
                        {
                            "text": "North Caucasian Federal District",
                            "value": 4
                        },
                        {
                            "text": "Volga Federal District",
                            "value": 5
                        },
                        {
                            "text": "Ural Federal District",
                            "value": 6
                        },
                        {
                            "text": "Siberian Federal District",
                            "value": 7
                        }
                    ]
                },
                {
                    "text": "South Africa",
                    "value": "SOUTHAFRICA",
                    "regions": [
                        {
                            "text": "Gauteng",
                            "value": 1
                        },
                        {
                            "text": "KwaZulu-Natal",
                            "value": 2
                        },
                        {
                            "text": "Western Cape",
                            "value": 3
                        },
                        {
                            "text": "Northern Cape",
                            "value": 4
                        },
                        {
                            "text": "Eastern Cape",
                            "value": 5
                        },
                        {
                            "text": "Limpopo",
                            "value": 6
                        },
                        {
                            "text": "Mpumalanga",
                            "value": 7
                        },
                        {
                            "text": "North West",
                            "value": 8
                        },
                        {
                            "text": "Free State",
                            "value": 9
                        }
                    ]
                },
                {
                    "text": "Spain",
                    "value": "SPAIN",
                    "regions": [
                        {
                            "text": "Navarra",
                            "value": 1
                        },
                        {
                            "text": "Aragón",
                            "value": 2
                        },
                        {
                            "text": "Cataluña",
                            "value": 3
                        },
                        {
                            "text": "Baleares, Islas",
                            "value": 4
                        },
                        {
                            "text": "Com. Valenciana",
                            "value": 5
                        },
                        {
                            "text": "Reg.Murcia",
                            "value": 6
                        },
                        {
                            "text": "Andalucía",
                            "value": 7
                        },
                        {
                            "text": "Canarias, Islas",
                            "value": 8
                        },
                        {
                            "text": "Ceuta",
                            "value": 9
                        },
                        {
                            "text": "Extemadura",
                            "value": 10
                        },
                        {
                            "text": "Melilla",
                            "value": 11
                        },
                        {
                            "text": "Com. Madrid",
                            "value": 12
                        },
                        {
                            "text": "Cantabria",
                            "value": 13
                        },
                        {
                            "text": "Asturias",
                            "value": 14
                        },
                        {
                            "text": "Rioja, La",
                            "value": 15
                        },
                        {
                            "text": "Euskadi",
                            "value": 16
                        },
                        {
                            "text": "Galicia",
                            "value": 17
                        },
                        {
                            "text": "Castilla La Mancha",
                            "value": 18
                        }
                    ]
                },
                {
                    "text": "Turkey",
                    "value": "TURKEY",
                    "regions": [
                        {
                            "text": "Marmara",
                            "value": 1
                        },
                        {
                            "text": "Aegean",
                            "value": 2
                        },
                        {
                            "text": "Central Anatolia",
                            "value": 3
                        },
                        {
                            "text": "Black Sea",
                            "value": 4
                        },
                        {
                            "text": "Mediterranean",
                            "value": 5
                        },
                        {
                            "text": "Eastern Anatolia",
                            "value": 6
                        },
                        {
                            "text": "Southeastern Anatolia",
                            "value": 7
                        }
                    ]
                },
                {
                    "text": "UK",
                    "value": "UK",
                    "regions": [
                        {
                            "text": "SOUTH (London, South East, South West)",
                            "value": 1
                        },
                        {
                            "text": "NORTH (North West, North East, Yorkshire)",
                            "value": 2
                        },
                        {
                            "text": "MID (East of England, West Midlands, East Midlands)",
                            "value": 3
                        },
                        {
                            "text": "REST OF UK (Scotland, Wales, Northern Ireland)",
                            "value": 4
                        }
                    ]
                },
                {
                    "text": "US",
                    "value": "US",
                    "regions": [
                        {
                            "text": "North-east",
                            "value": 1
                        },
                        {
                            "text": "South",
                            "value": 2
                        },
                        {
                            "text": "Mid-west",
                            "value": 3
                        },
                        {
                            "text": "West",
                            "value": 4
                        }
                    ]
                },
                {
                    "text": "Sweden",
                    "value": "SWEDEN",
                    "regions": [
                        {
                            "text": "Blekinge",
                            "value": 1
                        },
                        {
                            "text": "Skåne",
                            "value": 2
                        },
                        {
                            "text": "Dalarna",
                            "value": 3
                        },
                        {
                            "text": "Gävleborg",
                            "value": 4
                        },
                        {
                            "text": "Värmland",
                            "value": 5
                        },
                        {
                            "text": "Örebro",
                            "value": 6
                        },
                        {
                            "text": "Gotland",
                            "value": 7
                        },
                        {
                            "text": "Södermanland",
                            "value": 8
                        },
                        {
                            "text": "Stockholm",
                            "value": 9
                        },
                        {
                            "text": "Uppsala",
                            "value": 10
                        },
                        {
                            "text": "Västmanland",
                            "value": 11
                        },
                        {
                            "text": "Halland",
                            "value": 12
                        },
                        {
                            "text": "Västra Götaland",
                            "value": 13
                        },
                        {
                            "text": "Jämtland",
                            "value": 14
                        },
                        {
                            "text": "Norrbotten",
                            "value": 15
                        },
                        {
                            "text": "Västerbotten",
                            "value": 16
                        },
                        {
                            "text": "Västernorrland",
                            "value": 17
                        },
                        {
                            "text": "Jönköping",
                            "value": 18
                        },
                        {
                            "text": "Kalmar",
                            "value": 19
                        },
                        {
                            "text": "Kronoberg",
                            "value": 20
                        },
                        {
                            "text": "Östergötland",
                            "value": 21
                        }
                    ]
                }
            ]
        },
        "ageRange": [
            18,
            100
        ],
        "gender": [
            {
                "text": "Male",
                "value": "MALE"
            },
            {
                "text": "Female",
                "value": "FEMALE"
            }
        ],
        "education": [
            {
                "text": "Elementary school",
                "value": "ELEMENTARY SCHOOL"
            },
            {
                "text": "Senior high school",
                "value": "SENIOR HIGH SCHOOL"
            },
            {
                "text": "College",
                "value": "JUNIOR COLLEGE/TRADE SCHOOL"
            },
            {
                "text": "Graduate degree",
                "value": "College/University (Non-science degree)"
            },
            {
                "text": "Postgraduate degree",
                "value": "Graduate school (Non-science degree)"
            }
        ],
        "marital": [
            {
                "text": "Single",
                "value": "SINGLE"
            },
            {
                "text": "Married or living as married",
                "value": "Married/ living as married"
            },
            {
                "text": "Divorced, widowed, or separated",
                "value": "Divorced/ separated/ widowed"
            }
        ],
        "homeOwnership": [
            {
                "text": "Own outright",
                "value": "Owned outright"
            },
            {
                "text": "Own with a mortgage or loan",
                "value": "Owned with a mortgage or loan"
            },
            {
                "text": "Shared ownership",
                "value": "Shared ownership"
            },
            {
                "text": "Social rented",
                "value": "Social rented"
            },
            {
                "text": "Privately rented",
                "value": "Privately rented"
            },
            {
                "text": "Other (e.g. rent free)",
                "value": "Other (including living rent free)"
            }
        ],
        "urban": [
            {
                "text": "Urban",
                "value": "URBAN"
            },
            {
                "text": "Suburban",
                "value": "SUBURBAN"
            },
            {
                "text": "Rural",
                "value": "RURAL"
            }
        ],
        "ethnicity": [
            {
                "text": "Caucasian",
                "value": "Caucasian"
            },
            {
                "text": "African American",
                "value": "AFRICAN AMERICAN"
            },
            {
                "text": "Hispanic or Latino",
                "value": "Hispanic or Latino"
            },
            {
                "text": "Asian",
                "value": "Asian"
            },
            {
                "text": "Other",
                "value": "Other"
            }
        ],
        "profession": [
            {
                "text": "Nurse",
                "value": "Nurse"
            },
            {
                "text": "Farmer",
                "value": "Farmer"
            },
            {
                "text": "Engineer",
                "value": "Engineer"
            },
            {
                "text": "IT professional",
                "value": "IT professional"
            },
            {
                "text": "Non-profit NGO",
                "value": "Work for a non-governmental not-for-profit organisation"
            },
            {
                "text": "Educator",
                "value": "Teacher, lecturer, professor"
            },
            {
                "text": "Medical Doctor",
                "value": "Medical Doctor"
            },
            {
                "text": "Lawyer",
                "value": "Lawyer"
            },
            {
                "text": "Scientist",
                "value": "Scientist"
            },
            {
                "text": "Accountant",
                "value": "Accountant"
            },
            {
                "text": "Journalist",
                "value": "Journalist"
            },
            {
                "text": "Artist",
                "value": "Artist"
            },
            {
                "text": "Emergency services",
                "value": "Emergency services worker"
            },
            {
                "text": "Small business owner",
                "value": "Small business owner"
            },
            {
                "text": "Restaurant manager",
                "value": "Restaurant owner/manager"
            },
            {
                "text": "Military",
                "value": "Work for the military at officer level"
            },
            {
                "text": "Designer",
                "value": "Designer"
            },
            {
                "text": "Marketer",
                "value": "Marketer"
            },
            {
                "text": "Chef",
                "value": "Chef"
            },
            {
                "text": "Other",
                "value": "Other"
            }
        ]
    };

    setGenders(mapGenders(mappingResponse.gender));
    setMaritalStatuses(mapMaritalStatuses(mappingResponse.marital));
    setHomeOwnerships(mapHomeOwnerships(mappingResponse.homeOwnership));
    setEnvironments(mapUrban(mappingResponse.urban));
    setAgeRange(mapAgeRange(mappingResponse.ageRange));
    var educations = mapEducations(mappingResponse.education);
    setEducations(educations.educations);
    setEducationRange(educations.range);
    setTraits(mapTraits(mappingResponse.attitudes));
    setTraitSections(mapTraitSections(mappingResponse.attitudeSections));
    setAreasOfInterest(mapInterests(mappingResponse.interests));
    setContinents(mapContinents(mappingResponse.locations));
    setProfessions(mapProfessions(mappingResponse.profession));
  }

  function getGenderLabelByValue(value: string) {
    return genders.filter((g: ICheckboxListItem) => g.value === value)[0].label;
  }

  function getGenderCommaSeperatedList(values: string[]) {
    var genderList = '';
    values.forEach((v: string, index: number) => {
      genderList += getGenderLabelByValue(v);
      if (index !== values.length - 1) {
        genderList += ', ';
      }
    });
    return genderList;
  }

  function getMaritalStatusByValue(value: string) {
    return maritalStatuses.filter((m: ICheckboxListItem) => m.value === value)[0].label;
  }

  function getMaritalStatusCommaSeperatedList(values: string[]) {
    var maritalList = '';
    values.forEach((v: string, index: number) => {
      maritalList += getMaritalStatusByValue(v);
      if (index !== values.length - 1) {
        maritalList += ', ';
      }
    });
    return maritalList;
  }

  function getHomeOwnershipByValue(value: string) {
    return homeOwnerships.filter((h: ICheckboxListItem) => h.value === value)[0].label;
  }

  function getEducationLabelsByMinMax(min: string, max: string): string[] {
    var minIndex = educations.findIndex((e: IEducation) => e.value === min);
    var maxIndex = educations.findIndex((e: IEducation) => e.value === max);
    var values: string[] = [];
    educations.forEach((edu: IEducation) => {
      var index = educations.findIndex((e: IEducation) => e.value === edu.value)
      if (index >= minIndex && index <= maxIndex) {
        values.push(edu.text);
      }
    });
    return values;
  }

  function getProfessionByValue(value: string) {
    return professions.filter((h: ICheckboxListItem) => h.value === value)[0].label;
  }

  function getPositiveTraitLabelsByIndex(indexes: number[]) {
    var positives = traits.filter((t: ITrait) => indexes.indexOf(t.groupName) > -1).map((t: ITrait) => t.positiveText);
    return positives;
  }

  function getNegativeTraitLabelsByIndex(indexes: number[]) {
    var negatives = traits.filter((t: ITrait) => indexes.indexOf(t.groupName) > -1).map((t: ITrait) => t.negativeText);
    return negatives;
  }

  function getInterestsByValue(value: number) {
    return areasOfInterest.filter((a: ICheckboxListItem) => a.value.toString() === value.toString())[0]?.label;
  }

  function getUrbanByValue(value: string) {
    return environments.filter((e: ICheckboxListItem) => e.value === value)[0].label;
  }

  function getCountryTagsByAudienceId(id: string): string[] {
    var audience: any = audiences.find((a: IAudience) => a.id.toString() === id);
    var allRegions: string[] = [];
    if (audience !== undefined) {
      audience.questionnaire.countries.map((c: any) => {
        c.regions.map((r: any) => {
          var regionName = mapRegionFromCountryAndValue(c.name, r, continents);
          allRegions.push(`${c.name} - ${regionName}`)
        });
      });
      return allRegions;
    }
    return allRegions;
  }

  async function getSampleSize() {
    var questionnaire = createFubiObject();
    var sampleSizeResponse = await API.post("API", "/fubi/preview", { body: { ...questionnaire.questionnaire } });
    setSampleSize(sampleSizeResponse.sample_size);
  }

  async function getAudiences() {
    setFirstLoad(true);
    
    // The database might have fallen asleep and lead to a timeout. This isn't ideal,
    // but the request timing out before it's woken up is a "known" at this point, and
    // so we can handle it as if it's not an error. This is slightly hacky though.
    try {
        const a = await API.get("API", "/fubi", {});
        setAudiences(a);
        setFirstLoad(false);
        return;
    } catch {
        console.error("Error fetching audiences the first time.");
    }

    // It's failed, so we'll try again.

    try {
        const a = await API.get("API", "/fubi", {});
        setAudiences(a);
        setFirstLoad(false);
        return;
    } catch {
        toast.error("Error getting your audiences.", { position: toast.POSITION.BOTTOM_RIGHT, theme: "light", icon: <img src={IconToastError} /> });
    }
    
    setFirstLoad(false);
  }

  async function deleteAudience(id: string): Promise<boolean> {
    var success = await API.del("API", `/fubi/${id}`, { headers: {}, body: {} })
      .then(async () => {
        toast.success("Audience deleted", { position: toast.POSITION.BOTTOM_RIGHT, theme: "light", icon: <img src={IconToastSuccess} /> });
        await getAudiences();
        return true;
      })
      .catch(() => {
        toast.error("Error deleting audience.", { position: toast.POSITION.BOTTOM_RIGHT, theme: "light", icon: <img src={IconToastError} /> });
        return false;
      });
    return success;
  }

  async function getDefaultAudiences() {
    var defaultsResponse = {
        "defaults": [
            {
                "name": "Masters of the Universe",
                "description": {
                    "headline": "Goal driven",
                    "description": "Practical and cerebral"
                },
                "rewriteInput": {
                    "major": {
                        "index": 0,
                        "weight": 1
                    },
                    "minor": {
                        "index": 0,
                        "weight": 0
                    }
                }
            },
            {
                "name": "Visionaries",
                "description": {
                    "headline": "Idea driven",
                    "description": "Seek knowledge for its own sake"
                },
                "rewriteInput": {
                    "major": {
                        "index": 1,
                        "weight": 1
                    },
                    "minor": {
                        "index": 1,
                        "weight": 0
                    }
                }
            },
            {
                "name": "Disruptors",
                "description": {
                    "headline": "Adrenaline driven",
                    "description": "Live for challenge and disruption"
                },
                "rewriteInput": {
                    "major": {
                        "index": 2,
                        "weight": 1
                    },
                    "minor": {
                        "index": 2,
                        "weight": 0
                    }
                }
            },
            {
                "name": "Chief Fun Officers",
                "description": {
                    "headline": "Play driven",
                    "description": "Live for games and memes"
                },
                "rewriteInput": {
                    "major": {
                        "index": 3,
                        "weight": 1
                    },
                    "minor": {
                        "index": 3,
                        "weight": 0
                    }
                }
            },
            {
                "name": "Hearts & Souls",
                "description": {
                    "headline": "Emotion driven",
                    "description": "The centre of heartwarming stories"
                },
                "rewriteInput": {
                    "major": {
                        "index": 4,
                        "weight": 1
                    },
                    "minor": {
                        "index": 4,
                        "weight": 0
                    }
                }
            },
            {
                "name": "Campaigners",
                "description": {
                    "headline": "Values driven",
                    "description": "Want to improve the world and themselves"
                },
                "rewriteInput": {
                    "major": {
                        "index": 5,
                        "weight": 1
                    },
                    "minor": {
                        "index": 5,
                        "weight": 0
                    }
                }
            }
        ]
    };

    setDefaultAudiences(defaultsResponse.defaults);
  }

  async function onCreateAudience() {
    const id = toast.loading("Creating audience...", { position: toast.POSITION.BOTTOM_RIGHT, theme: "light", style: { fontSize: "large", fontFamily: "Mont-SemiBold", backgroundColor: "#FFA901", color: "white" } });
    var questionnaire = createFubiObject();
    API.post("API", "/fubi", { body: { ...questionnaire } })
      .then(async () => {
        await getAudiences();
        await resetData();
      }).finally(() => {
        toast.dismiss(id);
      }).then(() => {
        toast.success("Audience created successfully.", { position: toast.POSITION.BOTTOM_RIGHT, theme: "light", style: { fontSize: "large", fontFamily: "Mont-SemiBold" }, icon: false, closeButton: false, hideProgressBar: true });
      }).catch((error) => {
        console.error(error);
        toast.error("Error creating audience.", { position: toast.POSITION.BOTTOM_RIGHT, theme: "light", icon: <img src={IconToastError} />, style: { fontSize: "large", fontFamily: "Mont-SemiBold" } });
      });
  }

  function createFubiObject() {
    var interests = getSelectedAreasOfInterest()
      .map((a) => {
        return a.value
      });

    var positiveAttitudes = getSelectedPositiveTraits().map((a) => a.groupName);

    var negativeAttitudes = getSelectedNegativeTraits().map((a) => a.groupName);

    var selectedAreas = getSelectedContinents()
      .map((continent: IContinent) => {
        return getSelectedCountriesByArea(continent).map((country: ICountry) => {
          return {
            name: country.name,
            regions: country.regions.filter((region: IRegion) => region.checked).map((region: IRegion) => {
              return region.value;
            })
          }
        });
      });

    var countries = [];
    for (var i = 0; i < selectedAreas.length; i++) {
      for (var j = 0; j < selectedAreas[i].length; j++) {
        countries.push(selectedAreas[i][j]);
      }
    }

    var fubiObject = {
      name: newAudienceName,
      questionnaire: {
        customPrompt,
        countries: countries,
        demographic: {
          sex: genders.filter((gender: ICheckboxListItem) => gender.checked).map((g: ICheckboxListItem) => g.value),
          age: [ageRange.minimum, ageRange.maximum],
          education: [educations[educationRange.minimum].value, educations[educationRange.maximum].value],
          marital: maritalStatuses.filter((maritalStatus: ICheckboxListItem) => maritalStatus.checked).map((ms: ICheckboxListItem) => ms.value),
          homeOwnership: homeOwnerships.filter((homeOwnership: ICheckboxListItem) => homeOwnership.checked).map((ho: ICheckboxListItem) => ho.value),
          urban: environments.filter((environment: ICheckboxListItem) => environment.checked).map((e: ICheckboxListItem) => e.value),
          professions: professions.filter((profession: ICheckboxListItem) => profession.checked).map((e: ICheckboxListItem) => e.value)
        },
        attitudes: {
          positive: positiveAttitudes,
          negative: negativeAttitudes
        },
        interests: interests
      }
    }

    return fubiObject;
  }

  async function selectAudienceTemplate(audienceName: string) {
    var audience = audiences.find((a) => a.name === audienceName);
    console.log(audience);
    if (audience !== undefined) {
      setFromTemplate(true);
      setCustomPrompt((audience as any)?.questionnaire?.customPrompt || '');
      setContinents(mapRegionsFromSelected(audience, continents));
      setTraits(mapTraitsFromSelected(audience, traits));
      setAreasOfInterest(mapAreasOfInterestFromSelected(audience, areasOfInterest));
      setAgeRange(mapAgeRangeFromSelected(audience));
      setEducationRange(mapEducationFromSelected(audience, educations));
      setMaritalStatuses(mapMaritalStatusesFromSelected(audience, maritalStatuses));
      setGenders(mapGendersFromSelected(audience, genders));
      setHomeOwnerships(mapHomeOwnershipsFromSelected(audience, homeOwnerships));
      setProfessions(mapProfessionsFromSelected(audience, professions));
      setEnvironments(mapEnvironmentFromSelected(audience, environments));
    }
  }
  //#endregion

  //#region Locations
  function onContinentSelected(continent: IContinent, checked: boolean) {
    var tempData = [...continents];
    var tempContinent: IContinent | undefined = tempData.find((c) => c === continent);
    if (tempContinent !== undefined) {
      tempContinent.checked = checked;
      setContinents(tempData);
    }
  }

  function onCountrySelected(country: ICountry) {
    var tempData = [...continents];
    var tempContinent: IContinent | undefined = tempData.find((a) => a.countries && a.countries.find((r: any) => r === country));
    if (tempContinent !== undefined) {
      var tempCountry: ICountry | undefined = tempContinent.countries.find((r: any) => r === country);
      if (tempCountry !== undefined) {
        tempCountry.checked = !tempCountry.checked;
        setContinents(tempData);
      }
    }
  }

  function onRegionSelected(region: IRegion) {
    var tempData = [...continents];
    var tempContinent: IContinent | undefined = tempData.find((a: IContinent) => a.countries && a.countries.find((country: ICountry) => country.regions && country.regions.find((r: any) => r === region)));
    if (tempContinent !== undefined) {
      var tempCountry: ICountry | undefined = tempContinent.countries.find((c: any) => c.regions && c.regions.find((r: any) => r === region));
      if (tempCountry !== undefined) {
        var tempRegion: IRegion | undefined = tempCountry.regions.find((r: any) => r === region);
        if (tempRegion !== undefined) {
          tempRegion.checked = !tempRegion.checked;
          setContinents(tempData);
        }
      }
    }
  }

  function getSelectedContinents() {
    return continents.filter((a: IContinent) => a.checked);
  }

  function getSelectedCountries() {
    return continents.filter((a: IContinent) => a.countries && a.countries.filter((c: ICountry) => c.checked).length > 0);
  }

  function getSelectedRegions() {
    return continents.filter((a: IContinent) => a.countries && a.countries.filter((c: ICountry) => c.regions && c.regions.filter((r: IRegion) => r.checked).length > 0).length > 0);
  }

  function getSelectedCountriesByArea(continent: IContinent) {
    return continent.countries.filter((c: any) => c.checked === true);
  }

  function toggleSelectAllCountriesInContinent(continent: IContinent, selectAll: boolean) {
    var copy = [...continents];
    copy.forEach((c: IContinent) => {
      if (c === continent) {
        c.countries.forEach((country: ICountry) => {
          country.checked = selectAll;
        });
      }
    });
    setContinents(copy);
  }

  function toggleSelectAllRegionsInCountry(country: ICountry, selectAll: boolean) {
    var copy = [...continents];
    copy.forEach((cont: IContinent) => {
      cont.countries.forEach((c: ICountry) => {
        if (c === country) {
          c.regions.forEach((region: IRegion) => {
            region.checked = selectAll;
          });
        }
      });
    });
    setContinents(copy);
  }
  //#endregion

  //#region Ranges

  function updateAgeRange(min: number, max: number) {
    setAgeRange({ minimum: min, maximum: max });
  }

  function updateEducationRange(min: number, max: number) {
    setEducationRange({ minimum: min, maximum: max });
  }
  //#endregion

  //#region Gender
  function updateGenders(gender: ICheckboxListItem) {
    var copy = [...genders];
    var selectedGender = copy.find((g) => g.value === gender.value);
    if (selectedGender !== undefined) {
      selectedGender.checked = !selectedGender.checked;
    }
    setGenders(copy);
  }
  //#endregion

  //#region Marital Status
  function updateMaritalStatuses(status: ICheckboxListItem) {
    var copy = [...maritalStatuses];
    var selectedStatus = copy.find((s) => s.value === status.value);
    if (selectedStatus !== undefined) {
      selectedStatus.checked = !selectedStatus.checked;
    }
    setMaritalStatuses(copy);
  }

  //#endregion

  //#region Profession
  function updateProfessions(status: ICheckboxListItem) {
    var copy = [...professions];
    var selectedProfession = copy.find((s) => s.value === status.value);
    if (selectedProfession !== undefined) {
      selectedProfession.checked = !selectedProfession.checked;
    }
    setProfessions(copy);
  }

  //#endregion

  //#region Home Ownership
  function updateHomeOwnerships(ownership: ICheckboxListItem) {
    var copy = [...homeOwnerships];
    var selectedOwnership = copy.find((o) => o.value === ownership.value);
    if (selectedOwnership !== undefined) {
      selectedOwnership.checked = !selectedOwnership.checked;
    }
    setHomeOwnerships(copy);
  }
  //#endregion

  //#region Environment
  function updateEnvironments(environment: ICheckboxListItem) {
    var copy = [...environments];
    var selectedEnvironment = copy.find((e) => e.value === environment.value);
    if (selectedEnvironment !== undefined) {
      selectedEnvironment.checked = !selectedEnvironment.checked;
    }
    setEnvironments(copy);
  }
  //#endregion

  //#region Traits
  function updateTraits(groupName: number, value: TraitConnotation) {
    var copy = [...traits];
    var trait = copy.find((t) => t.groupName === groupName);
    if (trait !== undefined) {
      trait.selected = value;
      setTraits(copy);
    }
  }

  function getSelectedTraits(): ITrait[] {
    return traits.filter((t: ITrait) => t.selected !== TraitConnotation.Disabled);
  }

  function getSelectedPositiveTraits(): ITrait[] {
    return traits.filter((t: ITrait) => t.selected === TraitConnotation.Positive);
  }

  function getSelectedNegativeTraits(): ITrait[] {
    return traits.filter((t: ITrait) => t.selected === TraitConnotation.Negative);
  }
  //#endregion

  //#region Areas of Interest

  function updateAreasOfInterest(Name: string) {
    var copy = [...areasOfInterest];
    var areaOfInterest = copy.find((t) => t.label === Name);
    if (areaOfInterest !== undefined) {
      areaOfInterest.checked = !areaOfInterest.checked;
      setAreasOfInterest(copy);
    }
  }

  function getSelectedAreasOfInterest(): ICheckboxListItem[] {
    return areasOfInterest.filter((t: ICheckboxListItem) => t.checked);
  }
  //#endregion

  //#region Reset
  async function resetData() {
    setNewAudienceName('');
    setCustomPrompt('');
    await getMappings();
  }
  //#endregion

  return <AudienceContext.Provider value={{
    newAudienceName,
    setNewAudienceName,
    customPrompt,
    setCustomPrompt,
    audiences,
    onCreateAudience,
    continentSelected: getSelectedContinents().length > 0,
    countrySelected: getSelectedCountries().length > 0,
    regionSelected: getSelectedRegions().length > 0,
    onCountrySelected,
    onRegionSelected,
    ageRange,
    educationRange,
    updateAgeRange,
    updateEducationRange,
    maritalStatuses,
    updateMaritalStatuses,
    genders,
    updateGenders,
    homeOwnerships,
    updateHomeOwnerships,
    professions,
    updateProfessions,
    environments,
    updateEnvironments,
    traits: traits,
    traitSections,
    updateTraits,
    areasOfInterest,
    updateAreasOfInterest,
    canAddMoreTraits: getSelectedTraits().length < 3,
    canAddMoreAreasOfInterest: getSelectedAreasOfInterest().length < 3,
    resetData,
    fromTemplate,
    setFromTemplate,
    selectAudienceTemplate,
    defaultAudiences,
    educations,
    continents,
    onContinentSelected,
    sampleSize,
    getSampleSize,
    getGenderLabelByValue,
    getMaritalStatusByValue,
    getEducationLabelsByMinMax,
    getUrbanByValue,
    getHomeOwnershipByValue,
    getPositiveTraitLabelsByIndex,
    getNegativeTraitLabelsByIndex,
    getCountryTagsByAudienceId,
    getInterestsByValue,
    getGenderCommaSeperatedList,
    getMaritalStatusCommaSeperatedList,
    toggleSelectAllCountriesInContinent,
    toggleSelectAllRegionsInCountry,
    deleteAudience,
    getProfessionByValue,
    firstLoad,
  }} {...props} />;
}

function useAudience() {
  return useContext(AudienceContext);
}

export { AudienceProvider, useAudience };