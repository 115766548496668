import {IContinent} from "../interfaces/IContinent";
import {IRegion} from "../interfaces/IRegion";
import {ICountry} from "../interfaces/ICountry";
import {capitalizeString} from "../../../../modules/Utils";

export function mapContinents(mappings: any) {
    return mappings.continentsMapping.map((m: any) => {
        return {
            name: m.name,
            countries: m.countries.map((c: string) => mapCountries(mappings, c)),
            checked: true
        } as IContinent
    });
}

function mapCountries(mappings: any, selectedCountry: string) {
    var countryToMap = mappings.countries.find((c: any) => c.value === selectedCountry);
    if(countryToMap !== undefined) {
        return {
            name: countryToMap.text,
            regions: countryToMap.regions.map((r: any) => {return {text: r.text, value: r.value, checked: true} as IRegion}),
            checked: true
        } as ICountry;
    }
}

export function mapRegionsFromSelected(selectedAudience: any, continents: IContinent[]) {
    var tempContinents = [...continents];
    var selectedCountries = selectedAudience.questionnaire.countries;

    tempContinents.forEach((c) => {
        c.checked = false;
        c.countries.forEach((country) => {
            country.checked = false;
            country.regions.forEach((region) => region.checked = false);
        });
    });

    for(var i = 0; i < selectedCountries.length; i++) {
        var continent = tempContinents.find((c:IContinent) => c.countries && c.countries.find((country: ICountry) => country.name.toLowerCase() === selectedCountries[i].name.toString().toLowerCase()));
        if(continent !== undefined) {
            continent.checked = true;

            var country = continent.countries.find((c: ICountry) => c.name.toLowerCase() === selectedCountries[i].name.toString().toLowerCase());
            if(country !== undefined) {
                country.checked = true;

                var regions = country.regions;
                for(var j = 0; j < regions.length; j++) {
                    var region = regions.find((r: IRegion) => r.value === selectedCountries[i].regions[j]);
                    if(region !== undefined) {
                        region.checked = true;
                    }
                }
            }
        }
    }
    return tempContinents;
}

export function mapRegionFromCountryAndValue(countryName: string, value: number, continents: IContinent[]) {
    var continent = continents.find((c: IContinent) => c.countries && c.countries.find((country: ICountry) => country.name.toLowerCase() === countryName.toLowerCase()));
    if(continent !== undefined) {
        var country = continent?.countries.find((c: ICountry) => c.name.toLowerCase() === countryName.toLowerCase());
        if (country !== undefined) {
            var region = country.regions.find((r: IRegion) => r.value === value);
            if (region !== undefined) {
                return region.text;
            }
        }
    }
}