import React, {useEffect, useState} from 'react';
import SideMenuItem from "./SideMenuItem";
import Logo from '../../../assets/images/Logo.svg';
import {administration, analytics, audiences, guides, root} from "../../../modules/Navigation";
import MenuIconFubilize from "../../../assets/icons/menu-icon-fubilizer.svg";
import MenuIconFubilizeInactive from "../../../assets/icons/menu-icon-fubilizer-inactive.svg";
import MenuIconAudiences from "../../../assets/icons/menu-icon-audiences.svg";
import MenuIconAudiencesInactive from "../../../assets/icons/menu-icon-audiences-inactive.svg";
import MenuIconGuideInactive from "../../../assets/icons/menu-icon-guide-inactive.svg";
import MenuIconGuide from "../../../assets/icons/menu-icon-guide.svg";
import MenuIconAdmin from "../../../assets/icons/menu-icon-admin.svg";
import MenuIconAdminInactive from "../../../assets/icons/menu-icon-admin-inactive.svg";
import MenuIconAnalytics from "../../../assets/icons/menu-icon-analytics.svg";
import MenuIconAnalyticsInactive from "../../../assets/icons/menu-icon-analytics-inactive.svg";
import MenuIconCTA from "../../../assets/icons/menu-icon-cta.svg";
import { useAudience } from '../context/AudienceContext';


export interface ISideMenu {
    showMobileMenu: boolean,
    setShowMobileMenu: Function,
    setHoveringMenu: Function,
    hoveringMenu: boolean
}

export default function SideMenu({showMobileMenu, setShowMobileMenu, setHoveringMenu, hoveringMenu}: ISideMenu) {
    const [canAnimate, setCanAnimate] = useState(false);
    const { audiences: _audiences, firstLoad } = useAudience();
    const [ hasCustomAudiences, setHasCustomAudiences] = useState(true);

    useEffect(() => {
        setCanAnimate(true);
    }, []);

    useEffect(() => {
        setHasCustomAudiences(_audiences.some(a => isCustom(a.rewriteInput?.major)));
    }, [_audiences])

    function isCustom(input: any) : input is ({ id: number }) {
        console.log(1, Number.isInteger(input?.id));
        return Number.isInteger(input?.id);
    } 
    
    return <>
        <div className={`side-menu__mobile-backing ${showMobileMenu === true ? "active" : ""}`} onClick={() => setShowMobileMenu(false)}></div>
        <div className={`side-menu ${canAnimate === true ? "side-menu--animation" : ""} ${showMobileMenu === true ? "side-menu__mobile-menu-open" : "side-menu__mobile-menu-closed"}`}
             onMouseEnter={() => setHoveringMenu(true)} onMouseLeave={() => setHoveringMenu(false)}>
            <div className={"grid-y side-menu__column"}>
                <img className="side-menu__mobile-header__logo" src={Logo} alt="Logo"/>
                <div className={"cell auto side-menu__main-nav-wrapper"}>
                    <SideMenuItem text={root.title}
                                  activeIcon={MenuIconFubilize}
                                  inactiveIcon={MenuIconFubilizeInactive}
                                  navigateTo={root.to}/>

                    {
                        (hasCustomAudiences || firstLoad) ? 
                        <></> :
                        <SideMenuItem text={audiences.title}
                                  activeIcon={MenuIconAudiences}
                                  inactiveIcon={MenuIconAudiencesInactive}
                                  navigateTo={audiences.to}/>
                    }
                    
                    {/*<SideMenuItem text={guides.title}*/}
                    {/*              disabled={true}*/}
                    {/*              activeIcon={MenuIconGuide}*/}
                    {/*              inactiveIcon={MenuIconGuideInactive}*/}
                    {/*              navigateTo={guides.to}/>*/}

                    {/*<SideMenuItem text={administration.title}*/}
                    {/*              disabled={true}*/}
                    {/*              activeIcon={MenuIconAdmin}*/}
                    {/*              inactiveIcon={MenuIconAdminInactive}*/}
                    {/*              navigateTo={administration.to}/>*/}

                    {/*<SideMenuItem text={analytics.title}*/}
                    {/*              disabled={true}*/}
                    {/*              activeIcon={MenuIconAnalytics}*/}
                    {/*              inactiveIcon={MenuIconAnalyticsInactive}*/}
                    {/*              navigateTo={analytics.to}/>*/}
                </div>
                {/*<div className={"cell shrink"}>*/}
                {/*    <img src={MenuIconCTA}/>*/}
                {/*</div>*/}
            </div>
        </div>

    </>
};