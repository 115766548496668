import IconLogout from "../../../assets/icons/icon-logout.svg";
import { Auth } from "@aws-amplify/auth";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { useEffect, useState } from "react";
import { API } from "@aws-amplify/api";
import Modal from "../layout/Modal";
import Terms from "../../lib/terms/terms";

interface IFubiUser {
  name: string
}

export default function ProfileBubble() {
  const [fubiUser, setFubiUser] = useState<IFubiUser>({ name: '' });
  const { user } = useAuthenticator((context) => [context.user]);
  let [showModal, setShowModal] = useState(false);


  useEffect(() => {
    API.get('API', '/auth/me', {}).then(setFubiUser);
  }, [])

  function handleLogout() {
    Auth.signOut();
  }

  return <>
    <Modal isOpen={showModal}
      size="full"
      children={
        <div style={{ height: "80vh" }}>
            <Terms />
        </div>
      }
      modalClosed={() => { setShowModal(false) }} cancelLabel="Close" />
    <div className="header__profile-bubble">
      <div className="profile-bubble textAlign--left">
        <div className="profile-bubble__user">
          {fubiUser !== null && fubiUser?.name !== '' &&
            <h3 className="profile-bubble__user-name display--block">{fubiUser.name}</h3>
          }
          <p className="profile-bubble__user-name display--block p--small">{user.attributes?.email}</p>
        </div>
        <div className="profile-bubble__buttons">
          <button className="button button--secondary button--large button--full-width margin__bottom--xs" onClick={() => setShowModal(true)}>Terms and conditions</button>
          <button className="button button--primary--outline button--large button--full-width button--with-icon" onClick={() => handleLogout()}><img src={IconLogout} />Log out</button>
        </div>
      </div>
    </div>
  </>
};