import React, {useState} from 'react';
import ProfileBubble from './ProfileBubble';
import IconChevronDown from '../../../assets/icons/icon-chevron-down.svg';
import BackButton from "./BackButton";
import IconBurger from '../../../assets/icons/icon-burger.svg';
import {useAuthenticator} from "@aws-amplify/ui-react";
import Logo from "../../../assets/images/Logo.svg";

export interface IHeader {
    title?: string,
    setShowMobileMenu: Function,
    showBackButton?: boolean,
    showBreadcrumb: boolean,
    contextNoPadding?: boolean
}

export default function Header({ title, setShowMobileMenu, showBackButton, showBreadcrumb, contextNoPadding}: IHeader) {

    const [showBubble, setShowBubble] = useState(false);
    const { user } = useAuthenticator((context) => [context.user]);

    function showMobileMenuClick() {
        setShowMobileMenu(true);
    };

    function closeBubble() {
		setShowBubble(false);
	};

    return <header className={`header ${contextNoPadding ? "no-padding" : ""}`}>
        <div className="header__wrapper">
            <div className="display--flex justifyContent--space-between alignItems--center">
                <div className="header__title--wrapper">
                    {showBackButton &&
                        <div className='padding__bottom--xxs'>
                            <BackButton/>
                        </div>
                    }
                    
                    
                    <div className="display--flex justifyContent--space-between alignItems--center width--full">
                        {title !== null && title !== undefined
                            &&
                            <div>
                                <h1 className="show-for-medium">{title}</h1>
                                <h1 className="h2 hide-for-medium">{title}</h1>
                            </div>
                        }
                        {
                            title === null || title === undefined && <img className="header__logo" src={Logo} alt="Logo"/>
                        }
                        <button className="button hide-for-medium" onClick={showMobileMenuClick}>
                            <img src={IconBurger}/>
                        </button>
                    </div>
                </div>
                

                { showBubble &&
                    <div className="bubble-transparency" onClick={closeBubble}></div>
                }

                <div className="display--flex justifyContent--flex-end alignItems--center show-for-medium">
                <div className={"cursor__pointer"}>
                            <div className="header__profile">
                                <button className="button display--flex alignItems--center" name="avatar" id="avatar-btn" onClick={() => setShowBubble(!showBubble)}>
                                    <div className="header__avatar">
                                        <p className="header__profile__avatar-initial">{user.attributes?.email?.substring(0,1)}</p>
                                    </div>
                                    <img src={IconChevronDown} className="header__profile__chevron" />
                                </button>
                                { showBubble &&
                                    <ProfileBubble />
                                }
                            </div>
                        </div>
                </div>
            </div>
        </div>
    </header>
};