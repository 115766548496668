import { useEffect, useState } from "react"
import InputText from "../../lib/inputs/InputText";
import InputTextArea from "../../lib/inputs/InputTextArea";
import Button from "../../lib/buttons/Button";
import IconSparkle from "../../../assets/icons/icon-sparkle.svg";
import IconCopy from "../../../assets/icons/icon-copy.svg";
import IconCopyDisabled from "../../../assets/icons/icon-copy-disabled.svg";
import IconRegenerate from "../../../assets/icons/icon-regenerate.svg";
import IconRegenerateDisabled from "../../../assets/icons/icon-regenerate-disabled.svg";
import IconToastSuccess from "../../../assets/icons/icon-toast-success.svg";
import IconToastError from "../../../assets/icons/icon-toast-error.svg";
import { toast } from "react-toastify";
import Modal from "../layout/Modal";
import CreateAudience from "../audiences/CreateAudience";
import { API } from "@aws-amplify/api";
import { useAudience } from "../context/AudienceContext";
import AudienceCard from "../audiences/AudienceCard";
import Spinner from "../../lib/spinner/Spinner";

export default function Fubilizer() {
  const { audiences, resetData, defaultAudiences, firstLoad } = useAudience();
  const [selectedAudience, setSelectedAudience] = useState<any>(null);
  const [input, setInput] = useState("");
  const [output, setOutput] = useState("");
  const [customPrompt, setCustomPrompt] = useState("");
  const [showCreateAudienceModal, setShowCreateAudienceModal] = useState(false);
  const [fubilizeLabel, setFubilizeLabel] = useState("Fubilize!");
  const [fubilizing, setFubilizing] = useState(false);
  const [fubilizeProgress, setFubilizeProgress] = useState(0);
  const [hasCustomAudiences, setHasCustomAudiences] = useState(false);

  async function chooseAudience(audience: any) {
    setSelectedAudience(audience);
    setCustomPrompt(audience?.questionnaire?.customPrompt || "");
  }

  function isCustom(input: any) : input is ({ id: number }) {
    return Number.isInteger(input?.id);
  } 

  useEffect(() => {
    setHasCustomAudiences(audiences.some(a => isCustom(a.rewriteInput?.major)));
  }, [audiences]);

  async function fubilize() {
    setOutput('');
    setFubilizeLabel("Fubilizing...");
    setFubilizing(true);

    if (selectedAudience && input && input !== "") {
      var payload = { copy: input, major: selectedAudience.rewriteInput.major, minor: selectedAudience.rewriteInput.minor, customPrompt }
      setFubilizing(true);
      let fubilizingInternal = true; // Internal state tracking because we can't rely on React :))))
      Promise.race([
        new Promise(() => {
          const apiTimeout = 30e3;
          const start = Date.now();
          const end = start + apiTimeout;
          const update = () => {
            const untilEnd = end - Date.now();
            setFubilizeProgress((apiTimeout - untilEnd) / (apiTimeout));
            if (fubilizingInternal && untilEnd > 0)
              setTimeout(update, 1000 / 24);
            else if (!fubilizingInternal) {
              setFubilizeProgress(1);
              setTimeout(() => setFubilizing(false), 400);
            }
          };
          update();
        }),
        API.post("API", "/fubify", { body: payload })
          .then(response => {
            setOutput(response.message)

            var outputElm = document.getElementById('outputRef')
            if (outputElm != null || outputElm != undefined) {
              outputElm.focus();
            }
          })
      ]).catch(() => {
        toast.error("Error fubilizing.", { position: toast.POSITION.BOTTOM_RIGHT, theme: "light", icon: <img src={IconToastError} /> });
      }).finally(async () => {
        fubilizingInternal = false;
        setFubilizeLabel("Fubilize!");
      })
    }
  }

  function handleInputTextChange(e: React.ChangeEvent<HTMLInputElement>) {
    setInput(e.target.value)
  }

  function handleCustomTextChange(e: React.ChangeEvent<HTMLInputElement>) {
    setCustomPrompt(e.target.value)
  }

  function handleOutTextChange(e: React.ChangeEvent<HTMLInputElement>) {
    setOutput(e.target.value)
  }

  function copy() {
    navigator.clipboard.writeText(output);
    toast.success("Copied to clipboard.", { position: toast.POSITION.BOTTOM_RIGHT, theme: "light", icon: <img src={IconToastSuccess} /> });
  }

  function onCreateAudienceModalClosed() {
    resetData();
    document.body.classList.toggle('scroll--hidden')
    setShowCreateAudienceModal(false);
  }

  function openModal() {
    document.body.classList.toggle('scroll--hidden')
    setShowCreateAudienceModal(true);
  }

  return (<>
    <div className="fubilizer__layout">
      <div className="fubilizer__input-output-section">
        <p className="margin__bottom--xxxs">Input</p>
        <InputTextArea className="fubilizer__input-output-section-text-area" value={input} onChangeText={handleInputTextChange} placeholder={"Paste your draft copy here."} />
        <InputText className="margin__bottom--xxxs" value={customPrompt} onChangeText={handleCustomTextChange} placeholder="Custom instructions: e.g. be informal, in the style of a tweet..." />
        <Button
          text={fubilizeLabel + (fubilizing ? ` (${Math.round(100 * fubilizeProgress)}%)` : '')}
          className={"margin__bottom--s"}
          type={"primary"}
          destructive={false}
          outline={fubilizing}
          size={"large"}
          disabled={fubilizing || selectedAudience === null || selectedAudience === undefined || input === null || input === ""}
          onClick={() => fubilize()}
          children={<img src={IconSparkle} />}
        />
        <p className="margin__bottom--xxxs">Output</p>
        <InputTextArea className="fubilizer__input-output-section-text-area margin__bottom--xs" id="outputRef" value={output} onChangeText={handleOutTextChange} placeholder={"Your Fubilized copy will appear here!"} />
        <div className="display--flex justifyContent--flex-end gap--xs fubilizer__button-wrapper">
          <Button
            text={"Regenerate"}
            className={""}
            type={"secondary"}
            destructive={false}
            outline={true}
            size={"large"}
            disabled={output === ""}
            onClick={() => fubilize()}
            children={<img src={output === "" ? IconRegenerateDisabled : IconRegenerate} />}
          />
          <Button
            text={"Copy"}
            className={""}
            type={"secondary"}
            destructive={false}
            outline={false}
            size={"large"}
            disabled={output === ""}
            onClick={() => copy()}
            children={<img src={output === "" ? IconCopyDisabled : IconCopy} />}
          />
        </div>

      </div>
      <div className="fubilizer__audiences-section">
        <div className="fubilizer__audiences-section-box margin__bottom--s">
          <div className="display--flex margin__bottom--s fubilizer__audiences-header">
            {
                hasCustomAudiences 
                ? <>
                    <h3 className="fubilizer__audiences-header-title">Custom Audiences</h3>
                </>
                : <>
                    <h3 className="fubilizer__audiences-header-title">My Custom Audiences</h3>
                    <Button
                    text={"+ Create new"}
                    className={"fubilizer__audiences-header-button"}
                    type={"secondary"}
                    destructive={false}
                    outline={false}
                    size={"small"}
                    disabled={firstLoad}
                    onClick={openModal}
                    children={null}
                    />
                </>
            }
          </div>
          
          {
            firstLoad ?
            <div className="fubilizer-waiter">
              <Spinner className="fubilizer-waiter_spinner" />
              <span className="fubilizer-waiter_l1">Loading...</span>
              <span className="fubilizer-waiter_l2">This may take a while!</span>
            </div> :
            <div className="fubilizer__audiences">
              {audiences && audiences.length === 0 ? <p>You have no custom audiences</p> :
                <>
                  {audiences && audiences.map((audience: any, i: number) => {
                    return <AudienceCard key={`custom-audience-${i}`} elementKey={i} selected={selectedAudience === audience} audience={audience} onSelect={() => chooseAudience(audience)} />
                  })}
                </>
              }
            </div> 
            
          }
        </div>

        <div className="fubilizer__audiences-section-box--defaults">
          <h3 className="margin__bottom--xs">Fubilizer audiences</h3>

          {
            firstLoad ?
            <div className="fubilizer-waiter">
                <Spinner className="fubilizer-waiter_spinner" />
                <span className="fubilizer-waiter_l1">Loading...</span>
                <span className="fubilizer-waiter_l2">This may take a while!</span>
            </div> :
            <div className="fubilizer__audiences">
                {defaultAudiences && defaultAudiences.map((audience: any, i: number) => {
                return <AudienceCard key={`default-audience-${i}`} elementKey={i} selected={selectedAudience === audience} audience={audience} onSelect={() => setSelectedAudience(audience)} />
                })}
            </div>
          }

        </div>
      </div>
    </div>
    <Modal isOpen={showCreateAudienceModal} modalClosed={() => onCreateAudienceModalClosed()} children={<CreateAudience onComplete={() => onCreateAudienceModalClosed()} />} />
  </>
  )
}