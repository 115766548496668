import Layout from "../layout/Layout";
import * as Navigation from "../../../modules/Navigation";
import AudiencesList from "../audiences/AudiencesList";

export default function AudiencesPage() {
    return (
        <Layout pageTitle={Navigation.audiences.title} showBreadcrumb={false} dynamicViewSize={true}>
            <AudiencesList/>
        </Layout>
    )
}