import Button from "../../../lib/buttons/Button";
import IconLeft from "../../../../assets/icons/icon-chevron-left.svg";
import {useAudience} from "../../context/AudienceContext";
import {useState} from "react";

interface IStepButtons{
    onBack: Function;
    onNext: Function;
    backLabel?: string;
    nextLabel?: string;
    nextDisabled?: boolean;
    skipLabel?: string;
    skipShown?: boolean;
}
export default function StepButtons({onBack, onNext, backLabel = "Back", nextLabel = "Next", nextDisabled = false, skipLabel = "Skip", skipShown = false}: IStepButtons){
    // const {getSampleSize} = useAudience();
    const [disable, setDisable] = useState<boolean>(false);

    async function selectNext() {
        setDisable(true);
        // await getSampleSize();
        setDisable(false);
        onNext();
    }

    return (<div className="display--flex justifyContent--space-between">
        <Button
            text={backLabel}
            className={""}
            type={"text-only"}
            destructive={false}
            outline={true}
            size={"large"}
            disabled={false}
            onClick={() => onBack()}
            children={<img src={IconLeft}/>}
        />

        { skipShown ? (<Button
            text={skipLabel}
            className={"margin__left--auto margin__right--s"}
            type={"text-only"}
            destructive={false}
            outline={false}
            size={"large"}
            disabled={disable}
            onClick={() => selectNext()}
            children={null}
        />) : null }

        <Button
            text={nextLabel}
            className={""}
            type={"secondary"}
            destructive={false}
            outline={false}
            size={"large"}
            disabled={nextDisabled || disable}
            onClick={() => selectNext()}
            children={null}
        />
    </div>);
}
