import {ICheckboxListItem} from "../interfaces/ICheckboxListItem";

export function mapGenders(mappings: any) {
    return mappings.map((m: any) => {
        return {
            label: m.text,
            value: m.value,
            checked: true
        } as ICheckboxListItem
    });
}

export function mapGendersFromSelected(selectedAudience: any, genders: ICheckboxListItem[]) {
    var tempGenders = [...genders];
    var statuses = selectedAudience.questionnaire.demographic.sex;

    tempGenders.forEach((g) => g.checked = false);

    for(var i = 0; i < statuses.length; i++) {
        var gender = tempGenders.find((m) => m.value === statuses[i]);
        if(gender !== undefined) {
            gender.checked = true;
        }
    }
    return tempGenders;
}